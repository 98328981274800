import DateFnsUtils from '@date-io/date-fns'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { Grid, ListItem, ListItemText, TextField } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import { InputUploadAllType } from 'components/Input/InputUploadAllType'
import { DocumentType, Status } from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import uuid from 'uuid'

interface CertificateForm {
  IssueDate: Date
  ExpiryDate: Date
  CertificateName: string
  RegNo: string
  CertificateUrl: string
  Attachment: string
}

export const CertificateForm = (props: any) => {
  // Define section
  const history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const requiredField = 'This field is required'
  const mode = state?.mode
  const PersonnelCertificateID = state?.CertificateID
  const CertificateInfo = state?.ListingData?.find(
    x => x?.section_name === 'Certificate'
  )?.DataList
  const CurrentInfo = CertificateInfo?.find(
    x => x?.PersonnelCertificateID === PersonnelCertificateID
  )

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
    formState: { isDirty },
    register,
    reset,
  } = useForm<CertificateForm>({
    defaultValues: {
      IssueDate: CurrentInfo?.IssueDate || null,
      ExpiryDate: CurrentInfo?.ExpiryDate || null,
      CertificateName: CurrentInfo?.Description || '',
      RegNo: CurrentInfo?.RegNo || '',
      CertificateUrl: CurrentInfo?.CertificateUrl || '',
      Attachment: CurrentInfo?.DocumentID || '',
    },
  })

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  const [imagePreview, setImagePreview] = useState(null)
  const [selectedFileName, setSelectedFileName] = useState('')
  const [selectedFile, setSelectedFile] = useState({
    files: [],
  })
  const [isRemovedAttachment, setIsRemovedAttachment] = useState(false)

  // Query

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  useEffect(() => {
    if (CurrentInfo) {
      if (CurrentInfo?.DocumentInput?.Attachment) {
        setSelectedFile({
          files: [CurrentInfo?.DocumentInput?.Attachment],
        })

        let reader = new FileReader()

        reader.onloadend = () => {
          setImagePreview(reader.result)
        }
        reader.readAsDataURL(CurrentInfo?.DocumentInput?.Attachment)
      } else if (CurrentInfo?.Document?.DocumentFile) {
        setImagePreview(CurrentInfo?.Document?.DocumentFile)

        setValue('Attachment', CurrentInfo?.Document?.DocumentFile)
      }

      setSelectedFileName(CurrentInfo?.Document?.Description)
    }
  }, [CurrentInfo])

  // Function
  const onSubmit = (data: CertificateForm, addNew: boolean) => {
    setIsDisable(true)

    let docInput = null

    if (selectedFile.files.length > 0) {
      docInput = {
        DocumentType: DocumentType.PersonalDoc,
        Attachment: selectedFile.files[0] || null,
        Description: selectedFile.files[0] ? selectedFile.files[0].name : '',
        Status: Status.Active,
        FileSize: selectedFile.files[0].size,
      }
    }

    let input = {
      PersonnelCertificateID: CurrentInfo?.PersonnelCertificateID || uuid(),
      IssueDate: data?.IssueDate,
      ExpiryDate: data?.ExpiryDate,
      Description: data?.CertificateName,
      RegNo: data?.RegNo,
      CertificateUrl: data?.CertificateUrl,
      DocumentInput: docInput,
      DocumentID:
        isRemovedAttachment || !docInput
          ? null
          : CurrentInfo?.DocumentInput?.Attachment &&
            !CurrentInfo?.DocumentFile &&
            mode === 'Edit'
          ? CurrentInfo?.DocumentID
          : uuid(),
      Status: Status.Active,
    }

    if (mode === 'New') {
      CertificateInfo?.push(input)
    } else if (mode === 'Edit') {
      const indexToEdit = CertificateInfo?.findIndex(
        x => x?.PersonnelCertificateID === PersonnelCertificateID
      )

      if (indexToEdit !== -1) {
        CertificateInfo[indexToEdit] = input
      }
    }

    CertificateInfo?.sort(
      (a, b) =>
        new Date(b.IssueDate).getTime() - new Date(a.IssueDate).getTime()
    )

    if (addNew) {
      state?.ListingData?.map(x => {
        if (x?.section_name === 'Certificate') {
          return {
            ...x,
            DataList: CertificateInfo,
          }
        }

        return x
      })

      setIsDisable(false)
      reset()
      snackBar('Saved Successfully!', false)
    } else {
      snackBar('Saved Successfully!', true)
    }
  }

  const onChangeFile = event => {
    const file = event.target.files[0]

    if (!file) return

    setIsRemovedAttachment(false)
    if (file?.name === CurrentInfo?.Document?.Description) {
      let nextState = { ...selectedFile }
      nextState.files.splice(0, nextState.files.length)
      nextState.files.push(file)
      setSelectedFileName(file?.name)
      setSelectedFile(nextState)
      let reader = new FileReader()

      reader.onloadend = () => {
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    } else {
      let nextState = { ...selectedFile }
      nextState.files.splice(0, nextState.files.length)
      nextState.files.push(file)
      setSelectedFileName(file?.name)
      setSelectedFile(nextState)
      let reader = new FileReader()

      reader.onloadend = () => {
        const blob = new Blob([reader.result], { type: file.type })
        const url = URL.createObjectURL(blob)
        setImagePreview(url)
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...state,
        })
      }
    }, 2000)
  }

  return (
    <>
      <BasicHeader
        mainBtn="close"
        onClick={() => {
          if (isDirty) return setopenExitDialog(true)

          history.push(`/${type}/EApplication`, {
            ...state,
          })
        }}
        title="E-Application"
        primary={'Certificate'}
        themeColor={'#FF9800'}
      />

      <ContentWrapper externalBasicHeader footer>
        <CardContents>
          <Grid item xs={12} className="form-content">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="IssueDate"
                control={control}
                render={({ onChange, value, name }) => (
                  <KeyboardDatePicker
                    required
                    helperText={errors.IssueDate?.message}
                    error={!!errors.IssueDate}
                    label="Issued Date"
                    format="dd MMM yyyy"
                    className={`left`}
                    onChange={date => [onChange(date)]}
                    value={value}
                    name={name}
                    autoComplete="off"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: requiredField },
                }}
              />
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="ExpiryDate"
                control={control}
                render={({ onChange, value, name }) => (
                  <KeyboardDatePicker
                    required
                    helperText={
                      errors.ExpiryDate?.message ||
                      (value &&
                        new Date(value) < new Date(watch('IssueDate')) &&
                        'Expiry Date cannot be less than Issued Date')
                    }
                    error={
                      !!errors.ExpiryDate ||
                      (value && new Date(value) < new Date(watch('IssueDate')))
                    }
                    label="Expiry Date"
                    format="dd MMM yyyy"
                    className={`right`}
                    onChange={date => [onChange(date)]}
                    value={value}
                    name={name}
                    autoComplete="off"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: requiredField },
                  validate: value =>
                    new Date(value) > new Date(watch('IssueDate')) ||
                    'Expiry Date cannot be less than Issued Date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'CertificateName'}
              label="Certificate Name"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Certificate Name"
                  required
                  fullWidth
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.CertificateName?.message}
                  error={!!errors.CertificateName}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'RegNo'}
              label="Certificate Registration No."
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Certificate Registration No."
                  required
                  fullWidth
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.RegNo?.message}
                  error={!!errors.RegNo}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'CetificateUrl'}
              label="Certificate URL"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Certificate URL"
                  fullWidth
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name="Attachment"
              label="Attachment"
              control={control}
              ref={register}
              render={({ name, onChange }) => (
                <InputUploadAllType
                  value={selectedFileName}
                  label="Attachment"
                  handleUpload={e => {
                    onChangeFile(e)

                    const file = e.target.files[0]
                    if (file) {
                      onChange(file)
                    }
                  }}
                  className={`p-b-20`}
                  fullWidth
                  autoComplete="off"
                  name={name}
                />
              )}
            />
            {imagePreview !== null && (
              <div className="content-wrap full">
                <UploadPreview
                  src={imagePreview}
                  mediaType={selectedFile?.files[0]?.type}
                  onClick={() => {
                    setSelectedFile({ files: [] })
                    setSelectedFileName('')
                    setImagePreview(null)
                    setIsRemovedAttachment(true)
                  }}
                  remove
                />
              </div>
            )}
          </Grid>
        </CardContents>
      </ContentWrapper>

      <Footer
        externalDisplay
        options={
          mode === 'Edit'
            ? [
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, false))()
                  },
                  name: 'Save',
                  color: 'primary',
                  disabled: isDisable,
                },
              ]
            : [
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, true))()
                  },
                  name: 'Save & New',
                  color: 'primary',
                  disabled: isDisable,
                },
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, false))()
                  },
                  name: 'Save & Exit',
                  color: 'primary',
                  disabled: isDisable,
                },
              ]
        }
      />

      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <span
                      className="smTitle flex-space"
                      style={{
                        color: '#FF9800',
                      }}
                    >
                      Exit Confirmation
                    </span>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              Are you sure you want to exit? Your changes will not be saved.
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () =>
                    history.push(`/${type}/EApplication`, {
                      ...state,
                    }),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
