import DateFnsUtils from '@date-io/date-fns'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { Grid, ListItem, ListItemText, TextField } from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import { Status, useEApplicationListingQuery } from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import uuid from 'uuid'
import { lightenColor } from 'containers/helper/ColorConverter'
import useInputStyles from '../../CustomInputStyles'

interface MembershipForm {
  DateAdmitted: Date
  ProfessionalInstitution: string
  MembershipStatus: string
}

export const MembershipForm = (props: any) => {
  // Define section
  const history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const talentToken = state?.talentToken
  const SubscriptionInfo = state?.SubscriptionInfo
  const requiredField = 'This field is required'
  const mode = state?.mode
  const PersonnelMembershipID = state?.MembershipID
  const MembershipInfo = state?.ListingData?.find(
    x => x?.section_name === 'Professional Membership'
  )?.DataList
  const CurrentInfo = MembershipInfo?.find(
    x => x?.PersonnelMembershipID === PersonnelMembershipID
  )
  const JobPortal = state?.JobPortal
  const classes = useInputStyles(JobPortal?.theme_color)()

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
    formState,
    register,
    reset,
  } = useForm<MembershipForm>({
    defaultValues: {
      DateAdmitted: CurrentInfo?.DateAdmitted || null,
      ProfessionalInstitution: CurrentInfo?.ProfessionalInstitution || '',
      MembershipStatus: CurrentInfo?.MembershipStatus || '',
    },
  })

  const { isDirty } = formState

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [isDisable, setIsDisable] = useState(false)

  // Query

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  // Function
  const onSubmit = (data: MembershipForm, addNew: boolean) => {
    setIsDisable(true)
    let input = {
      PersonnelMembershipID: CurrentInfo?.PersonnelMembershipID || uuid(),
      DateAdmitted: data?.DateAdmitted,
      ProfessionalInstitution: data?.ProfessionalInstitution,
      MembershipStatus: data?.MembershipStatus,
      Status: Status.Active,
    }

    if (mode === 'New') {
      MembershipInfo?.push(input)
    } else if (mode === 'Edit') {
      const indexToEdit = MembershipInfo?.findIndex(
        x => x?.PersonnelMembershipID === PersonnelMembershipID
      )

      if (indexToEdit !== -1) {
        MembershipInfo[indexToEdit] = input
      }
    }

    MembershipInfo?.sort(
      (a, b) =>
        new Date(b.DateAdmitted).getTime() - new Date(a.DateAdmitted).getTime()
    )

    if (addNew) {
      state?.ListingData?.map(x => {
        if (x?.section_name === 'Professional Membership') {
          return {
            ...x,
            DataList: MembershipInfo,
          }
        }

        return x
      })

      setIsDisable(false)
      reset()
      snackBar('Saved Successfully!', false)
    } else {
      snackBar('Saved Successfully!', true)
    }
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...state,
        })
      }
    }, 2000)
  }

  return (
    <>
      <BasicHeader
        mainBtn="close"
        onClick={() =>
          isDirty
            ? setopenExitDialog(true)
            : history.push(`/${type}/EApplication`, {
                ...state,
              })
        }
        title="E-Application"
        primary={'Professional Membership'}
        themeColor={JobPortal?.theme_color || '#FF9800'}
      />

      <ContentWrapper externalBasicHeader footer>
        <CardContents>
          <Grid item xs={12} className="form-content">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="DateAdmitted"
                control={control}
                render={({ onChange, value, name }) => (
                  <KeyboardDatePicker
                    required
                    helperText={errors.DateAdmitted?.message}
                    error={!!errors.DateAdmitted}
                    label="Date Admitted"
                    format="dd MMM yyyy"
                    className={`${classes.datePicker}`}
                    onChange={date => [onChange(date)]}
                    value={value}
                    name={name}
                    autoComplete="off"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    DialogProps={{
                      className: classes.datePicker,
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: requiredField },
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'ProfessionalInstitution'}
              label="Professional Institution"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Professional Institution"
                  required
                  fullWidth
                  className={`${classes.textField}`}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.ProfessionalInstitution?.message}
                  error={!!errors.ProfessionalInstitution}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'MembershipStatus'}
              label="Membership Status"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Membership Status"
                  required
                  fullWidth
                  className={`${classes.textField}`}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.MembershipStatus?.message}
                  error={!!errors.MembershipStatus}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>
        </CardContents>
      </ContentWrapper>

      <Footer
        externalDisplay
        themeColor={JobPortal?.theme_color}
        options={
          mode === 'Edit'
            ? [
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, false))()
                  },
                  name: 'Save',
                  color: 'primary',
                  disabled: isDisable,
                },
              ]
            : [
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, true))()
                  },
                  name: 'Save & New',
                  color: 'primary',
                  disabled: isDisable,
                },
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, false))()
                  },
                  name: 'Save & Exit',
                  color: 'primary',
                  disabled: isDisable,
                },
              ]
        }
      />

      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        headerBgColor={lightenColor(JobPortal?.theme_color, 90)}
        sections={{
          header: {
            children: (
              <ListItem
                className="remove-padding"
                style={{
                  backgroundColor: lightenColor(JobPortal?.theme_color, 90),
                }}
              >
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{
                          color: JobPortal?.theme_color || '#FF9800',
                        }}
                      >
                        Exit Confirmation
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <>
              <div>
                Are you sure you want to exit? Your changes will not be saved.
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                  style: {
                    backgroundColor: JobPortal?.theme_color,
                  },
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () =>
                    history.push(`/${type}/EApplication`, {
                      ...state,
                    }),
                  variant: 'contained',
                  color: 'primary',
                  style: {
                    backgroundColor: JobPortal?.theme_color,
                  },
                },
              },
            ],
          },
        }}
      />

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
