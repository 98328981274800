import DateFnsUtils from '@date-io/date-fns'
import DefaultAvatar from '@ifca-root/react-component/src/assets/images/default-avatar.png'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Checkbox,
  Divider,
  Fab,
  FormControlLabel,
  FormHelperText,
  Grid,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Publish } from '@material-ui/icons'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AddressAutoComponent } from 'components/AddressAutocomplete/AddressComponent'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import { useAddressAutoComplete } from 'containers/helper/hooks/useAddressAutoComplete'
import dateFormat from 'dateformat'
import {
  DocumentType,
  Gender,
  Marital,
  Nationality,
  PersonnelParameterType,
  Race,
  Religion,
  useDataUpdateTalentPersonalQuery,
  useRecruitmentParameterListingQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'

interface PersonalInfoForm {
  ProfileImage: string
  Salutation: string
  Name: string
  Email: string
  NickName: string
  NricNo: string
  PassportNo: string
  PassportNoLHDN: string
  Gender: string
  MobileNo: string
  Dob: Date
  Dob2: Date
  Age: number
  Nationality: string
  Race: string
  Religion: string
  Marital: string
  PAddress: string
  PCountry: string
  PState: string
  PCity: string
  PPostCode: string
  SameAddress: boolean
  CAddress: string
  CCountry: string
  CState: string
  CCity: string
  CPostCode: string
  Disabled: boolean
}

export const DataUpdatePersonalInfoForm = (props: any) => {
  // Define section
  const history = useHistory()
  const { state: states }: any = useLocation()
  const { type } = props
  const SubscriptionInfo = states?.SubscriptionInfo
  const TalentInfo = states?.TalentInfo
  const requiredField = 'This field is required'
  const PersonalInfo = states?.ListingData?.find(
    x => x?.section_name === 'Personal Info'
  )?.DataList
  const ContactID = PersonalInfo?.Contact?.ContactID || null
  const TalentPoolID = PersonalInfo?.Talent?.rec_talent_pool_id || null

  const Nationalities: any[] = Object.values(Nationality).map(i => {
    return i.replace(/_/g, ' ')
  })
  const Races: any[] = Object.values(Race).map(i => {
    return i.replace(/_/g, ' ')
  })
  const Religions: any[] = Object.values(Religion)
  const Genders: any[] = Object.values(Gender)
  const MaritalStatus: any[] = Object.values(Marital)

  const {
    handleSubmit,
    errors,
    setError,
    clearErrors,
    control,
    setValue,
    getValues,
    watch,
    formState,
    register,
    reset,
  } = useForm<PersonalInfoForm>({
    defaultValues: {
      ProfileImage: PersonalInfo?.Contact?.DocumentID || '',
      Salutation: PersonalInfo?.Contact?.Salutation || '',
      Name: PersonalInfo?.Contact?.FullName || TalentInfo?.name || '',
      Email: PersonalInfo?.Contact?.Email || TalentInfo?.email || '',
      NickName: PersonalInfo?.Contact?.NickName || '',
      NricNo: PersonalInfo?.Contact?.NRIC || '',
      PassportNo: PersonalInfo?.Contact?.PassportNo || '',
      PassportNoLHDN: PersonalInfo?.Contact?.PassportNoLHDN || '',
      MobileNo: PersonalInfo?.Contact?.MobileNo || '',
      Gender: PersonalInfo?.Contact?.Gender || '',
      Dob: PersonalInfo?.Contact?.BirthDate
        ? dateFormat(PersonalInfo?.Contact?.BirthDate, 'dd mmm yyyy')
        : '',
      Dob2: PersonalInfo?.Contact?.BirthDate || null,
      Age: PersonalInfo?.Contact?.Age || null,
      Nationality: PersonalInfo?.Contact?.Nationality || Nationality.Malaysian,
      Race: PersonalInfo?.Contact?.Race || '',
      Religion: PersonalInfo?.Contact?.Religion || '',
      Marital: PersonalInfo?.Contact?.Marital || '',
      PAddress: PersonalInfo?.Contact?.PermanentAddress || '',
      PCountry: PersonalInfo?.Contact?.PCountry || '',
      PState: PersonalInfo?.Contact?.PState || '',
      PCity: PersonalInfo?.Contact?.PCity || '',
      PPostCode: PersonalInfo?.Contact?.PPostCode || '',
      SameAddress: PersonalInfo?.Contact?.SameAddress || false,
      CAddress: PersonalInfo?.Contact?.Address || '',
      CCountry: PersonalInfo?.Contact?.CCountry || '',
      CState: PersonalInfo?.Contact?.CState || '',
      CCity: PersonalInfo?.Contact?.CCity || '',
      CPostCode: PersonalInfo?.Contact?.CPostCode || '',
      Disabled: PersonalInfo?.Employee?.IsOKU || false,
    },
  })

  const { isDirty } = formState

  const {
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    autoCompleteRef,
    autoCompleteRefV2,
    state,
    stateV2,
    initializeEditValue,
    dispatchV2,
  } = useAddressAutoComplete()

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [imagePreview, setImagePreview] = useState(null)
  const [selectedFileName, setSelectedFileName] = useState('')
  const [selectedFile, setSelectedFile] = useState({
    files: [],
  })
  const [isDisable, setIsDisable] = useState(false)
  const [salutationListing, setSalutationListing] = useState([])
  const [country, setCountry] = useState('')
  const [countryV2, setCountryV2] = useState('')

  // Query
  const {
    loading: RecruitmentParameterListingLoading,
  } = useRecruitmentParameterListingQuery({
    variables: {
      SubscriptionAccountID: SubscriptionInfo?.SubscriptionAccountID,
      type: [PersonnelParameterType.Salutation],
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.RecruitmentParameterListing) {
        setSalutationListing(data?.RecruitmentParameterListing)
      }
    },
  })

  const {
    data: { DataUpdateTalentPersonal } = { DataUpdateTalentPersonal: null },
    loading: DataUpdateTalentPersonalLoading,
  } = useDataUpdateTalentPersonalQuery({
    fetchPolicy: 'no-cache',
    variables: {
      TalentPoolID,
    },
    onCompleted: data => {
      const result = data?.DataUpdateTalentPersonal

      if (result) {
        reset({
          ProfileImage:
            PersonalInfo?.Contact?.DocumentID ||
            result?.Contact?.DocumentID ||
            '',
          Salutation:
            PersonalInfo?.Contact?.Salutation ||
            result?.Contact?.Salutation ||
            '',
          Name:
            PersonalInfo?.Contact?.FullName ||
            result?.Contact?.FullName ||
            TalentInfo?.name ||
            '',
          Email:
            PersonalInfo?.Contact?.Email ||
            result?.Contact?.Email ||
            TalentInfo?.email ||
            '',
          NickName:
            PersonalInfo?.Contact?.NickName || result?.Contact?.NickName || '',
          NricNo: PersonalInfo?.Contact?.NRIC || result?.Contact?.NRIC || '',
          PassportNo:
            PersonalInfo?.Contact?.PassportNo ||
            result?.Contact?.PassportNo ||
            '',
          PassportNoLHDN:
            PersonalInfo?.Contact?.PassportNoLHDN ||
            result?.Contact?.PassportNoLHDN ||
            '',
          MobileNo:
            PersonalInfo?.Contact?.MobileNo || result?.Contact?.MobileNo || '',
          Gender:
            PersonalInfo?.Contact?.Gender || result?.Contact?.Gender || '',
          Dob:
            PersonalInfo?.Contact?.BirthDate || result?.Contact?.BirthDate
              ? dateFormat(
                  PersonalInfo?.Contact?.BirthDate ||
                    result?.Contact?.BirthDate,
                  'dd mmm yyyy'
                )
              : '',
          Dob2:
            PersonalInfo?.Contact?.BirthDate ||
            result?.Contact?.BirthDate ||
            null,
          Age: PersonalInfo?.Contact?.Age || result?.Contact?.Age || null,
          Nationality:
            PersonalInfo?.Contact?.Nationality ||
            result?.Contact?.Nationality ||
            Nationality.Malaysian,
          Race: PersonalInfo?.Contact?.Race || result?.Contact?.Race || '',
          Religion:
            PersonalInfo?.Contact?.Religion || result?.Contact?.Religion || '',
          Marital:
            PersonalInfo?.Contact?.Marital || result?.Contact?.Marital || '',
          PAddress:
            PersonalInfo?.Contact?.PermanentAddress ||
            result?.Contact?.PermanentAddress ||
            '',
          SameAddress:
            PersonalInfo?.Contact?.SameAddress ||
            result?.Contact?.SameAddress ||
            false,
          CAddress:
            PersonalInfo?.Contact?.Address || result?.Contact?.Address || '',
          Disabled:
            PersonalInfo?.Employee?.IsOKU || result?.Employee?.IsOKU || false,
        })

        if (
          PersonalInfo?.Contact?.PermanentAddress ||
          result?.Contact?.PermanentAddress
        ) {
          initializeEditValue(
            PersonalInfo?.Contact?.PermanentAddress ||
              result?.Contact?.PermanentAddress,
            'permanentAddress'
          )
        }

        if (PersonalInfo?.Contact?.Address || result?.Contact?.Address) {
          initializeEditValue(
            PersonalInfo?.Contact?.Address || result?.Contact?.Address,
            'presentAddress'
          )
        }

        if (result?.Contact?.DocumentFile) {
          setImagePreview(result?.Contact?.DocumentFile)
        }
      }
    },
  })

  // useEffect
  useEffect(() => {
    if (!states) {
      snackBar(`You don't have permission to access this page.`, false, null)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [states])

  useEffect(() => {
    if (PersonalInfo) {
      if (PersonalInfo?.Document?.Attachment) {
        setSelectedFile({
          files: [PersonalInfo?.Document?.Attachment],
        })

        let reader = new FileReader()

        reader.onloadend = () => {
          setImagePreview(reader.result)
        }
        reader.readAsDataURL(PersonalInfo?.Document?.Attachment)
      }
    }
  }, [PersonalInfo])

  useEffect(() => {
    if (
      watch('NricNo') === '' &&
      watch('Nationality') === Nationality.Malaysian
    ) {
      clearErrors('NricNo')
      setValue('Dob', '')
      setValue('Age', '-')
      setValue('PassportNo', '')
    }

    const NricElem = document.getElementById('NricNo')

    if (NricElem) {
      NricElem.onkeydown = (e: KeyboardEvent) => {
        const allowedKeys = [
          'Backspace',
          'Delete',
          'ArrowLeft',
          'ArrowRight',
          'Tab',
          'Control',
          'Meta',
          'Shift',
          'Alt',
          'Enter',
          'Escape',
        ]

        const isShortcut = e.ctrlKey || e.metaKey || e.altKey || e.shiftKey

        if (isShortcut || allowedKeys.includes(e.key) || /^\d$/.test(e.key)) {
          return // Allow the input
        }

        e.preventDefault()
      }

      NricElem.oninput = (e: Event) => {
        const target = e.target as HTMLInputElement
        const cursorPos = target.selectionStart ?? 0

        // Allow only numbers in the NRIC input
        let numericValue = ''
        for (const char of target.value) {
          if (!isNaN(Number(char))) {
            numericValue += char
          }
        }

        // Format the value with hyphens
        let formattedNric = numericValue
        if (numericValue.length > 6) {
          formattedNric = `${numericValue.slice(0, 6)}-${numericValue.slice(6)}`
        }
        if (numericValue.length > 8) {
          formattedNric = `${numericValue.slice(0, 6)}-${numericValue.slice(
            6,
            8
          )}-${numericValue.slice(8)}`
        }

        setValue('NricNo', formattedNric)

        // Calculate new cursor position
        let newCursorPos = cursorPos
        if (cursorPos > 6) newCursorPos += 1 // Account for the first hyphen
        if (cursorPos > 8) newCursorPos += 1 // Account for the second hyphen

        requestAnimationFrame(() => {
          target.setSelectionRange(newCursorPos, newCursorPos)
        })
      }
    }

    const nric = watch('NricNo')?.replace(/-/g, '')
    const nationality = watch('Nationality')

    if (nric?.length === 12 && nationality === Nationality.Malaysian) {
      const nricSplit = `${nric.slice(0, 6)}-${nric.slice(6, 8)}-${nric.slice(
        8
      )}`.split('-')

      if (
        nricSplit[0]?.length === 6 &&
        nricSplit[1]?.length === 2 &&
        nricSplit[2]?.length === 4
      ) {
        const year = nricSplit[0].substring(0, 2)
        const month = nricSplit[0].substring(2, 4)
        const day = nricSplit[0].substring(4)
        const currentYear = new Date()
          .getFullYear()
          .toString()
          .substring(2)
        const genderCode = Number(nricSplit[2].substring(3))

        // Determine gender
        setValue('Gender', genderCode % 2 === 0 ? 'Female' : 'Male')

        // Determine full year
        const fullYear = year > currentYear ? `19${year}` : `20${year}`

        // Validate month and day
        if (Number(month) >= 1 && Number(month) <= 12) {
          const date = new Date(`${fullYear}-${month}-${day}`)
          if (date.getDate() === Number(day) && !isNaN(date.getTime())) {
            // Check if date is valid
            setValue('Dob', dateFormat(date, 'dd mmm yyyy'))
            setValue('Age', calculateAge(date))
            clearErrors('NricNo')
            clearErrors('Gender')
          } else {
            setError('NricNo', {
              type: 'validate',
              message: 'Invalid NRIC No.',
            })
          }
        } else {
          setError('NricNo', {
            type: 'validate',
            message: 'Invalid NRIC No.',
          })
        }
      }
    } else if (nationality !== Nationality.Malaysian) {
      setValue('NricNo', '')
      if (watch('Dob2')) {
        setValue('Age', calculateAge(new Date(watch('Dob2'))))
      } else {
        setValue('Age', '-')
      }

      if (!watch('Gender')) {
        setValue('Gender', '')
      }
    }
  }, [watch('NricNo'), watch('Nationality'), watch('Dob2'), watch('Gender')])

  // Function
  const onSubmit = (data: PersonalInfoForm) => {
    setIsDisable(true)

    let documentInput = null

    let contactInput = {
      ...PersonalInfo?.Contact,
      ContactID,
      Salutation: data?.Salutation,
      FullName: data?.Name,
      Email: data?.Email,
      NickName: data?.NickName,
      MobileNo: data?.MobileNo,
      NRIC: data?.NricNo,
      PassportNo: data?.PassportNo,
      BirthDate: data?.Dob || data?.Dob2,
      Nationality: Object.values(Nationality).find(
        i => i === data?.Nationality?.replace(/ /g, '_')
      ),
      Race: Object.values(Race).find(i => i === data?.Race?.replace(/ /g, '_')),
      Religion: Object.values(Religion).find(
        i => i === data?.Religion?.replace(/ /g, '_')
      ),
      Gender: Object.values(Gender).find(
        i => i === data?.Gender?.replace(/ /g, '_')
      ),
      Marital: Object.values(Marital).find(
        i => i === data?.Marital?.replace(/ /g, '_')
      ),
      Age: data?.Age,
      Address: {
        City: stateV2?.city?.name,
        Line1: stateV2?.address,
        State: stateV2?.state?.name,
        Country: stateV2?.country?.name,
        PostalCode: stateV2?.postcode?.postcode,
      },
      SameAddress: data.SameAddress,
      PermanentAddress: {
        City: state?.city?.name,
        Line1: state?.address,
        State: state?.state?.name,
        Country: state?.country?.name,
        PostalCode: state?.postcode?.postcode,
      },
    }

    let employeeInput = {
      IsOKU: data?.Disabled,
    }

    let talentInput = {
      ...PersonalInfo?.Talent,
      rec_talent_pool_id: TalentPoolID,
      preferred_location: stateV2?.state?.name,
    }

    if (selectedFile.files.length > 0) {
      documentInput = {
        DocumentType: DocumentType.Employee,
        Attachment: selectedFile.files[0] || null,
        Description: selectedFile.files[0] ? selectedFile.files[0].name : '',
        FileSize: selectedFile.files[0].size,
      }
    }

    if (contactInput && talentInput) {
      const updatedData = {
        Contact: contactInput,
        Talent: talentInput,
        Employee: employeeInput,
        ...(documentInput ? { Document: documentInput } : {}),
      }

      snackBar('Saved Successfully!', true, updatedData)
    }
  }

  const CopyPermanentAddress = (Copy: boolean) => {
    if (Copy === true) {
      dispatchV2({
        type: 'address',
        payload: state.address,
      })
      dispatchV2({
        type: 'country',
        payload: state.country,
      })
      dispatchV2({
        type: 'state',
        payload: state.state,
      })
      dispatchV2({
        type: 'city',
        payload: state.city,
      })
      dispatchV2({
        type: 'postcode',
        payload: state.postcode,
      })
    } else {
      dispatchV2({
        type: 'address',
        payload: '',
      })
      dispatchV2({
        type: 'country',
        payload: '',
      })
      dispatchV2({
        type: 'state',
        payload: '',
      })
      dispatchV2({
        type: 'city',
        payload: '',
      })
      dispatchV2({
        type: 'postcode',
        payload: '',
      })
    }
  }

  const calculateAge = (date: Date) => {
    const ageDiffMs = Date.now() - date?.getTime()
    const ageDate = new Date(ageDiffMs)
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970)

    if (date) {
      if (date.getFullYear() === new Date().getFullYear()) {
        return 0
      } else {
        return calculatedAge
      }
    } else {
      return null
    }
  }

  const snackBar = (message: string, redirect: boolean, updatedData: any) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...states,
          ListingData: states?.ListingData?.map(x => {
            if (x?.section_name === 'Personal Info') {
              return {
                ...x,
                DataList: updatedData,
              }
            }

            return x
          }),
        })
      }
    }, 2000)
  }

  const onChangeFile = event => {
    const file = event.target.files[0]

    if (!file) return

    if (file?.name === PersonalInfo?.Contact?.Documents?.Description) {
      let nextState = { ...selectedFile }
      nextState.files.splice(0, nextState.files.length)
      nextState.files.push(file)
      setSelectedFileName(file?.name)
      setSelectedFile(nextState)
      let reader = new FileReader()

      reader.onloadend = () => {
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    } else {
      if (file && file.type.startsWith('image/')) {
        clearErrors('ProfileImage')
        let nextState = { ...selectedFile }
        nextState.files.splice(0, nextState.files.length)
        nextState.files.push(file)
        setSelectedFileName(file?.name)
        setSelectedFile(nextState)
        let reader = new FileReader()

        reader.onloadend = () => {
          const blob = new Blob([reader.result], { type: file.type })
          const url = URL.createObjectURL(blob)
          setImagePreview(url)
        }
        reader.readAsArrayBuffer(file)
      } else {
        setError('ProfileImage', {
          type: 'validate',
          message: 'Invalid file type!',
        })
        setSelectedFile({ files: [] })
        setSelectedFileName('')
        setImagePreview(null)

        return
      }
    }
  }

  return (
    <>
      <BasicHeader
        mainBtn="close"
        onClick={() => {
          if (isDirty) return setopenExitDialog(true)

          history.push(`/${type}/EApplication`, {
            ...states,
          })
        }}
        title="E-Application"
        primary={'Personal Info'}
        themeColor={'#FF9800'}
      />

      <ContentWrapper externalBasicHeader footer>
        <CardContents>
          <img
            src={imagePreview || DefaultAvatar}
            alt=""
            className="upload-user-avatar"
          />

          <Fab
            color="primary"
            size="small"
            aria-label="add"
            className="float-img-upload"
          >
            <label htmlFor="icon-button-file"></label>
            <Publish />
          </Fab>

          <Controller
            name={'ProfileImage'}
            control={control}
            ref={register}
            render={({ name, onChange }) => (
              <input
                type="file"
                hidden
                name={name}
                id="icon-button-file"
                disabled={DataUpdateTalentPersonalLoading}
                onChange={e => {
                  const file = e.target.files[0]
                  if (file && file.type.startsWith('image/')) {
                    onChangeFile(e)
                    onChange(file)
                    clearErrors('ProfileImage')
                  } else {
                    setError('ProfileImage', {
                      type: 'validate',
                      message: 'Invalid file type!',
                    })
                    setSelectedFile({ files: [] })
                    setSelectedFileName('')
                    setImagePreview(null)
                  }
                }}
              />
            )}
          />
          {errors?.ProfileImage && (
            <Grid item xs={12} className="form-content">
              <FormHelperText error style={{ textAlign: 'center' }}>
                {errors?.ProfileImage?.message}
              </FormHelperText>
            </Grid>
          )}

          <Grid item xs={12} className="form-content">
            <Controller
              name="Salutation"
              label="Salutation"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Salutation"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.Salutation?.message}
                  error={!!errors.Salutation}
                  disabled={
                    RecruitmentParameterListingLoading ||
                    DataUpdateTalentPersonalLoading
                  }
                >
                  {salutationListing.map((el, index) => (
                    <MenuItem key={index} value={el?.PersonnelParameterID}>
                      {el?.Name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'Name'}
              label="Name"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Name"
                  required
                  fullWidth
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.Name?.message}
                  error={!!errors.Name}
                  disabled={DataUpdateTalentPersonalLoading}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'NickName'}
              label="Nickname"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Nickname"
                  fullWidth
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  disabled={DataUpdateTalentPersonalLoading}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'Email'}
              label="Email"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Email"
                  required
                  fullWidth
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.Email?.message}
                  error={!!errors.Email}
                  disabled={DataUpdateTalentPersonalLoading}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name="Nationality"
              label="Nationality"
              required
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Nationality"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`left`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.Nationality?.message}
                  error={!!errors.Nationality}
                  disabled={DataUpdateTalentPersonalLoading}
                >
                  {Nationalities.map((el, index) => (
                    <MenuItem key={index} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />

            <Controller
              name={'MobileNo'}
              label="Mobile No."
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Mobile No."
                  required
                  fullWidth
                  className={`right`}
                  style={{ paddingLeft: '12px' }}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.MobileNo?.message}
                  error={!!errors.MobileNo}
                  InputLabelProps={{
                    style: {
                      left: '12px',
                    },
                  }}
                  disabled={DataUpdateTalentPersonalLoading}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
                pattern: {
                  value: /^[+-]?[0-9]*\.?[0-9]+$/,
                  message: 'Invalid Mobile No.',
                },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'NricNo'}
              label="NRIC No."
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="NRIC No."
                  id="NricNo"
                  fullWidth
                  required={watch('Nationality') === 'Malaysian'}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.NricNo?.message}
                  error={!!errors.NricNo}
                  disabled={
                    watch('Nationality') !== 'Malaysian' ||
                    DataUpdateTalentPersonalLoading
                  }
                />
              )}
              rules={{
                required: {
                  value: watch('Nationality') === 'Malaysian',
                  message: requiredField,
                },
                pattern: {
                  value: /^\d{6}-\d{2}-\d{4}$/i,
                  message: 'Invalid NRIC No.',
                },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'PassportNo'}
              label="Current Passport No."
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Current Passport No."
                  className={`left`}
                  fullWidth
                  required={watch('Nationality') !== 'Malaysian'}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.PassportNo?.message}
                  error={!!errors.PassportNo}
                  disabled={
                    watch('Nationality') === 'Malaysian' ||
                    DataUpdateTalentPersonalLoading
                  }
                />
              )}
              rules={{
                required: {
                  value: watch('Nationality') !== 'Malaysian',
                  message: requiredField,
                },
              }}
            />

            <Controller
              name={'PassportNoLHDN'}
              label="Passport No. (LHDN)"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Passport No. (LHDN)"
                  className={`right`}
                  style={{ paddingLeft: '12px' }}
                  fullWidth
                  required={watch('Nationality') !== 'Malaysian'}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.PassportNoLHDN?.message}
                  error={!!errors.PassportNoLHDN}
                  disabled={
                    watch('Nationality') === 'Malaysian' ||
                    DataUpdateTalentPersonalLoading
                  }
                  InputLabelProps={{
                    style: {
                      left: '12px',
                    },
                  }}
                />
              )}
              rules={{
                required: {
                  value: watch('Nationality') !== 'Malaysian',
                  message: requiredField,
                },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            {watch('Nationality') === 'Malaysian' ? (
              <Controller
                name="Dob"
                label="Birth Date"
                required
                fullWidth
                control={control}
                render={({ onChange, value, name }) => (
                  <TextField
                    label="Birth Date"
                    value={value}
                    name={name}
                    className={`left`}
                    autoComplete="off"
                    disabled={DataUpdateTalentPersonalLoading}
                    // helperText={validateDate && 'NRIC is incorrect'}
                    // error={validateDate}
                  />
                )}
              />
            ) : (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  name="Dob2"
                  control={control}
                  render={({ onChange, value, name }) => (
                    <KeyboardDatePicker
                      required
                      helperText={errors.Dob2?.message}
                      error={!!errors.Dob2}
                      label="Birth Date"
                      format="dd MMM yyyy"
                      onChange={date => [onChange(date)]}
                      value={value}
                      className={`left`}
                      name={name}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={DataUpdateTalentPersonalLoading}
                    />
                  )}
                  rules={{
                    required: {
                      value: watch('Nationality') !== 'Malaysian',
                      message: requiredField,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            )}
            <Controller
              name={'Age'}
              label="Age"
              control={control}
              render={({ name, value }) => (
                <TextField
                  name={name}
                  label="Age"
                  fullWidth
                  value={value ?? '-'}
                  disabled
                  className={`right`}
                  style={{ paddingLeft: '12px' }}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                    style: {
                      color: 'black',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      left: '12px',
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name="Gender"
              label="Gender"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Gender"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`left`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.Gender?.message}
                  error={!!errors.Gender}
                  disabled={DataUpdateTalentPersonalLoading}
                >
                  {Genders.map((el, index) => (
                    <MenuItem key={index} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />

            <Controller
              name="Marital"
              label="Marital Status"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Marital Status"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`right`}
                  style={{ paddingLeft: '12px' }}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.Marital?.message}
                  error={!!errors.Marital}
                  disabled={DataUpdateTalentPersonalLoading}
                  InputLabelProps={{
                    style: {
                      left: '12px',
                    },
                  }}
                >
                  {MaritalStatus.map((el, index) => (
                    <MenuItem key={index} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name="Race"
              label="Race"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Race"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`left`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.Race?.message}
                  disabled={DataUpdateTalentPersonalLoading}
                  error={!!errors.Race}
                >
                  {Races.map((el, index) => (
                    <MenuItem key={index} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />

            <Controller
              name="Religion"
              label="Religion"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Religion"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`right`}
                  style={{ paddingLeft: '12px' }}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.Religion?.message}
                  error={!!errors.Religion}
                  disabled={DataUpdateTalentPersonalLoading}
                  InputLabelProps={{
                    style: {
                      left: '12px',
                    },
                  }}
                >
                  {Religions.map((el, index) => (
                    <MenuItem key={index} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              control={control}
              name={`Disabled`}
              render={({ name, value, onChange }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={name}
                      checked={value}
                      onChange={e => onChange(e.target.checked)}
                      color="primary"
                      disabled={DataUpdateTalentPersonalLoading}
                    />
                  }
                  label="Disability / OKU"
                />
              )}
            />
          </Grid>

          <Divider style={{ backgroundColor: '#78828c21', width: '100%' }} />

          <Grid item xs={12} className="form-content">
            <AddressAutoComponent
              id="permanentAddress"
              getCountry={getCountry}
              getStatebyCountry={getStatebyCountry}
              getCitybyState={getCitybyState}
              getPostcodebyCity={getPostcodebyCity}
              handleCountryOnChange={handleCountryOnChange}
              handleStateOnChange={handleStateOnChange}
              handleCityOnChange={handleCityOnChange}
              handlePostCodeOnChange={handlePostCodeOnChange}
              handleGoogleOnChange={handleGoogleOnChange}
              autoCompleteRef={autoCompleteRef}
              state={state}
              country={country}
              setCountry={setCountry}
              formType={'edit'}
              isSubmitted={formState?.isSubmitted}
              isMandatory={true}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              control={control}
              name={`SameAddress`}
              render={({ name, value, onChange }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={name}
                      checked={value}
                      onChange={e => {
                        onChange(e.target.checked)
                        CopyPermanentAddress(e.target.checked)
                      }}
                      color="primary"
                      disabled={DataUpdateTalentPersonalLoading}
                    />
                  }
                  label="Permanent address same as present address"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <AddressAutoComponent
              id="presentAddress"
              getCountry={getCountry}
              getStatebyCountry={getStatebyCountry}
              getCitybyState={getCitybyState}
              getPostcodebyCity={getPostcodebyCity}
              handleCountryOnChange={handleCountryOnChange}
              handleStateOnChange={handleStateOnChange}
              handleCityOnChange={handleCityOnChange}
              handlePostCodeOnChange={handlePostCodeOnChange}
              handleGoogleOnChange={handleGoogleOnChange}
              autoCompleteRef={autoCompleteRefV2}
              state={stateV2}
              country={countryV2}
              setCountry={setCountryV2}
              formType={'edit'}
              isSubmitted={formState?.isSubmitted}
              isMandatory={true}
            />
          </Grid>
        </CardContents>
      </ContentWrapper>

      <Footer
        externalDisplay
        options={[
          {
            onClick: () => {
              handleSubmit(onSubmit)()
            },
            name: 'Save',
            color: 'primary',
            disabled: isDisable,
          },
        ]}
      />

      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <span
                      className="smTitle flex-space"
                      style={{
                        color: '#FF9800',
                      }}
                    >
                      Exit Confirmation
                    </span>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              Are you sure you want to exit? Your changes will not be saved.
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () =>
                    history.push(`/${type}/EApplication`, {
                      ...states,
                    }),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
