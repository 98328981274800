import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Grid,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import { lightenColor } from 'containers/helper/ColorConverter'
import {
  LanguageLevel,
  PersonnelParameterType,
  Status,
  useRecruitmentParameterListingQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import uuid from 'uuid'
import useInputStyles from '../../CustomInputStyles'

interface LanguageForm {
  LanguageID: string
  ReadLevel: string
  WrittenLevel: string
  SpokenLevel: string
}

export const LanguageForm = (props: any) => {
  // Define section
  let history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const requiredField = 'This field is required'
  const mode = state?.mode
  const SubscriptionInfo = state?.SubscriptionInfo
  const PersonnelLanguageID = state?.LanguageID
  const LanguageInfo = state?.ListingData?.find(
    x => x?.section_name === 'Languages'
  )?.DataList
  const CurrentInfo = LanguageInfo?.find(
    x => x?.PersonnelLanguageID === PersonnelLanguageID
  )
  const JobPortal = state?.JobPortal
  const classes = useInputStyles(JobPortal?.theme_color)()
  const DefaultLanguage = ['English', 'Malay', 'Chinese']

  let LanguageLevels: any[] = Object.values(LanguageLevel).map(i => {
    return i.replace(/_/g, ' ')
  })

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
    formState,
    register,
    reset,
  } = useForm<LanguageForm>({
    defaultValues: {
      LanguageID: CurrentInfo?.LanguageID || '',
      ReadLevel: CurrentInfo?.ReadLevel || '',
      WrittenLevel: CurrentInfo?.WrittenLevel || '',
      SpokenLevel: CurrentInfo?.SpokenLevel || '',
    },
  })

  const { isDirty } = formState

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [Languages, setLanguages] = useState([])
  const [LanguageList, setLanguageList] = useState([])
  const [isDisable, setIsDisable] = useState(false)
  const [exitForm, setExitForm] = useState(false)

  // Query
  const {
    data: { RecruitmentParameterListing } = { RecruitmentParameterListing: [] },
    loading: RecruitmentParameterListingLoading,
  } = useRecruitmentParameterListingQuery({
    fetchPolicy: 'no-cache',
    variables: {
      SubscriptionAccountID: SubscriptionInfo?.SubscriptionAccountID,
      type: [PersonnelParameterType.Language],
    },
    onCompleted: data => {
      setLanguageList(data?.RecruitmentParameterListing)
    },
  })

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  useEffect(() => {
    if (!LanguageInfo?.length && LanguageList?.length) {
      LanguageSetup()
    }
  }, [LanguageInfo, LanguageList])

  // Function
  const onSubmit = (data: LanguageForm, addNew: boolean) => {
    setIsDisable(true)
    let input = {
      PersonnelLanguageID: CurrentInfo?.PersonnelLanguageID || uuid(),
      LanguageID: data?.LanguageID,
      Language: LanguageList?.find(
        x => x?.PersonnelParameterID === data?.LanguageID
      )?.Name,
      ReadLevel: data?.ReadLevel,
      WrittenLevel: data?.WrittenLevel,
      SpokenLevel: data?.SpokenLevel,
      Status: Status.Active,
    }

    if (mode === 'New' && !LanguageInfo?.length) {
      LanguageInfo?.push(...Languages)
    } else if (mode === 'New' && LanguageInfo?.length) {
      LanguageInfo?.push(input)
    } else if (mode === 'Edit') {
      const indexToEdit = LanguageInfo?.findIndex(
        x => x?.PersonnelLanguageID === PersonnelLanguageID
      )

      if (indexToEdit !== -1) {
        LanguageInfo[indexToEdit] = input
      }
    }

    if (addNew) {
      state?.ListingData?.map(x => {
        if (x?.section_name === 'Languages') {
          return {
            ...x,
            DataList: LanguageInfo,
          }
        }

        return x
      })

      setIsDisable(false)
      reset()
      snackBar('Saved Successfully!', false)
    } else {
      if (LanguageInfo?.length === DefaultLanguage?.length) {
        setExitForm(true)
      }
      snackBar('Saved Successfully!', true)
    }
  }

  const LanguageSetup = () => {
    let initialLanguage = []

    initialLanguage = LanguageList?.filter(x => x?.IsDefault)?.map(el => ({
      PersonnelLanguageID: uuid(),
      LanguageID: el?.PersonnelParameterID,
      Language: el?.Name,
      ReadLevel: null,
      WrittenLevel: null,
      SpokenLevel: null,
      Status: Status.Active,
    }))

    setLanguages(initialLanguage)
  }

  const handleLevelChange = (type, level, index) => {
    setLanguages(prev =>
      prev.map((el, i) => {
        if (i === index) {
          switch (type) {
            case 'read':
              return {
                ...el,
                ReadLevel: level,
              }

            case 'written':
              return {
                ...el,
                WrittenLevel: level,
              }

            case 'spoken':
              return {
                ...el,
                SpokenLevel: level,
              }
          }
        }

        return el
      })
    )
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...state,
        })
      }
    }, 2000)
  }

  return (
    <>
      <BasicHeader
        mainBtn="close"
        onClick={() =>
          isDirty
            ? setopenExitDialog(true)
            : history.push(`/${type}/EApplication`, {
                ...state,
              })
        }
        title="E-Application"
        primary={'Languages'}
        themeColor={JobPortal?.theme_color || '#FF9800'}
      />

      <ContentWrapper externalBasicHeader footer>
        <CardContents>
          {!LanguageInfo?.length ||
          (LanguageInfo?.length === DefaultLanguage?.length && exitForm) ? (
            LanguageList?.filter(x => x?.IsDefault)?.map((el, index) => (
              <>
                <Grid item xs={12} className="form-content">
                  <Controller
                    name={`Language-${index}`}
                    label="Language"
                    control={control}
                    ref={register}
                    defaultValue={el}
                    render={({ onChange, value, name }) => (
                      <TextField
                        name={name}
                        label="Language"
                        required
                        fullWidth
                        className="left"
                        autoComplete="off"
                        value={el?.Name}
                        helperText={errors[`Language-${index}`]?.message}
                        error={!!errors[`Language-${index}`]}
                        InputProps={{
                          style: {
                            color: JobPortal?.theme_color || '#FF9800',
                            fontWeight: 'bold',
                          },
                          disableUnderline: true,
                          readOnly: true,
                        }}
                      />
                    )}
                    rules={{
                      required: { value: true, message: requiredField },
                    }}
                  />

                  <Controller
                    name={`ReadLevel-${index}`}
                    label="Read Level"
                    control={control}
                    ref={register}
                    defaultValue={''}
                    render={({ onChange, value, name }) => (
                      <TextField
                        select
                        name={name}
                        label="Read Level"
                        required
                        fullWidth
                        className={`${classes.textField} right`}
                        autoComplete="off"
                        value={value}
                        onChange={e => {
                          onChange(e.target.value)
                          handleLevelChange('read', e.target.value, index)
                        }}
                        helperText={errors[`ReadLevel-${index}`]?.message}
                        error={!!errors[`ReadLevel-${index}`]}
                      >
                        {LanguageLevels.map((el, index) => (
                          <MenuItem key={index} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    rules={{
                      required: { value: true, message: requiredField },
                    }}
                  />
                </Grid>

                <Grid item xs={12} className="form-content">
                  <Controller
                    name={`WrittenLevel-${index}`}
                    label="Written Level"
                    control={control}
                    ref={register}
                    defaultValue={''}
                    render={({ onChange, value, name }) => (
                      <TextField
                        select
                        name={name}
                        label="Written Level"
                        required
                        fullWidth
                        className={`${classes.textField} left`}
                        autoComplete="off"
                        value={value}
                        onChange={e => {
                          onChange(e.target.value)
                          handleLevelChange('written', e.target.value, index)
                        }}
                        helperText={errors[`WrittenLevel-${index}`]?.message}
                        error={!!errors[`WrittenLevel-${index}`]}
                      >
                        {LanguageLevels.map((el, index) => (
                          <MenuItem key={index} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    rules={{
                      required: { value: true, message: requiredField },
                    }}
                  />

                  <Controller
                    name={`SpokenLevel-${index}`}
                    label="Spoken Level"
                    control={control}
                    ref={register}
                    defaultValue={''}
                    render={({ onChange, value, name }) => (
                      <TextField
                        select
                        name={name}
                        label="Spoken Level"
                        required
                        fullWidth
                        className={`${classes.textField} right`}
                        autoComplete="off"
                        value={value}
                        onChange={e => {
                          onChange(e.target.value)
                          handleLevelChange('spoken', e.target.value, index)
                        }}
                        helperText={errors[`SpokenLevel-${index}`]?.message}
                        error={!!errors[`SpokenLevel-${index}`]}
                      >
                        {LanguageLevels.map((el, index) => (
                          <MenuItem key={index} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    rules={{
                      required: { value: true, message: requiredField },
                    }}
                  />
                </Grid>
              </>
            ))
          ) : (
            <>
              <Grid item xs={12} className="form-content">
                <Controller
                  name={'LanguageID'}
                  label="Language"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select={
                        !DefaultLanguage?.includes(CurrentInfo?.Language) ||
                        mode === 'New'
                      }
                      name={name}
                      label="Language"
                      required
                      fullWidth
                      className={`${classes.textField} left`}
                      autoComplete="off"
                      value={
                        DefaultLanguage?.includes(CurrentInfo?.Language)
                          ? CurrentInfo?.Language
                          : value
                      }
                      onChange={e => onChange(e.target.value)}
                      helperText={errors.LanguageID?.message}
                      error={!!errors.LanguageID}
                      InputProps={
                        DefaultLanguage?.includes(CurrentInfo?.Language)
                          ? {
                              style: {
                                color: JobPortal?.theme_color || '#FF9800',
                                fontWeight: 'bold',
                              },
                              disableUnderline: true,
                              readOnly: true,
                            }
                          : {}
                      }
                    >
                      {(!DefaultLanguage?.includes(CurrentInfo?.Language) ||
                        mode === 'New') &&
                        LanguageList?.filter(x => !x?.IsDefault)?.map(
                          (el, index) => (
                            <MenuItem
                              key={index}
                              value={el?.PersonnelParameterID}
                            >
                              {el?.Name}
                            </MenuItem>
                          )
                        )}
                    </TextField>
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />

                <Controller
                  name={'ReadLevel'}
                  label="Read Level"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      name={name}
                      label="Read Level"
                      required
                      fullWidth
                      className={`${classes.textField} right`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.ReadLevel?.message}
                      error={!!errors.ReadLevel}
                    >
                      {LanguageLevels.map((el, index) => (
                        <MenuItem key={index} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'WrittenLevel'}
                  label="Written Level"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      name={name}
                      label="Written Level"
                      required
                      fullWidth
                      className={`${classes.textField} left`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.WrittenLevel?.message}
                      error={!!errors.WrittenLevel}
                    >
                      {LanguageLevels.map((el, index) => (
                        <MenuItem key={index} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />

                <Controller
                  name={'SpokenLevel'}
                  label="Spoken Level"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      name={name}
                      label="Spoken Level"
                      required
                      fullWidth
                      className={`${classes.textField} right`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.SpokenLevel?.message}
                      error={!!errors.SpokenLevel}
                    >
                      {LanguageLevels.map((el, index) => (
                        <MenuItem key={index} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
              </Grid>
            </>
          )}
        </CardContents>
      </ContentWrapper>

      <Footer
        externalDisplay
        themeColor={JobPortal?.theme_color}
        options={
          mode === 'Edit'
            ? [
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, false))()
                  },
                  name: 'Save',
                  color: 'primary',
                  disabled: isDisable,
                },
              ]
            : [
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, true))()
                  },
                  name: 'Save & New',
                  color: 'primary',
                  disabled: isDisable,
                },
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, false))()
                  },
                  name: 'Save & Exit',
                  color: 'primary',
                  disabled: isDisable,
                },
              ]
        }
      />

      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        headerBgColor={lightenColor(JobPortal?.theme_color, 90)}
        sections={{
          header: {
            children: (
              <ListItem
                className="remove-padding"
                style={{
                  backgroundColor: lightenColor(JobPortal?.theme_color, 90),
                }}
              >
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{
                          color: JobPortal?.theme_color || '#FF9800',
                        }}
                      >
                        Exit Confirmation
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <>
              <div>
                Are you sure you want to exit? Your changes will not be saved.
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                  style: {
                    backgroundColor: JobPortal?.theme_color,
                  },
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () =>
                    history.push(`/${type}/EApplication`, {
                      ...state,
                    }),
                  variant: 'contained',
                  color: 'primary',
                  style: {
                    backgroundColor: JobPortal?.theme_color,
                  },
                },
              },
            ],
          },
        }}
      />

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
