import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import React, { cloneElement, useState, useContext } from 'react'
import { useRouteMatch } from 'react-router'
import Drawer from './Drawer'
import Header from './Header'
import AppContext from 'containers/App/Store/AppContext'
import { Skeleton } from '@material-ui/lab'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    mainContent: {
      position: 'relative',
      top: 64,
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        top: 64,
        marginLeft: -drawerWidth,
        padding: theme.spacing(3),
        // minWidth:'calc(100&-240px)!important',
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%!important',
      },
    },

    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },

    toolbar: theme.mixins.toolbar,
  })
)

interface Props {
  loading?: boolean
  children: any
  mobile?: boolean
}

const Layout = (props: Props) => {
  const { loading, children, mobile } = props
  const classes = useStyles()
  const theme = useTheme()
  const settings = useRouteMatch({
    path: [
      '/authentication/404',
      '/login',
      '/login-sso',
      '/MFAVerify',
      '/forgot',
      '/forgot-password',
      '/create-password',
      '/resetPassword',
      '/confirm',
      '/ProjectTimesheet/Approver',
      '/JobPortal/AvailableVacancyListing',
      '/JobPortal/AvailableVacancyDetail',
      '/JobPortal/JobPortalForm',
      '/JobPortal/EApplication',
      '/JobPortal/EApplicationSubmitted',
      '/JobPortal/EApplication/PersonalInfoForm',
      '/JobPortal/EApplication/EducationForm',
      '/JobPortal/EApplication/ProfessionalMembershipForm',
      '/JobPortal/EApplication/EmploymentHistoryForm',
      '/JobPortal/EApplication/LanguagesForm',
      '/JobPortal/EApplication/ReferencesForm',
      '/JobPortal/EApplication/SupportingDocumentsForm',
      '/TalentInvitation/EApplication',
      '/TalentInvitation/EApplicationSubmitted',
      '/TalentInvitation/EApplication/PersonalInfoForm',
      '/TalentInvitation/EApplication/EducationForm',
      '/TalentInvitation/EApplication/ProfessionalMembershipForm',
      '/TalentInvitation/EApplication/EmploymentHistoryForm',
      '/TalentInvitation/EApplication/LanguagesForm',
      '/TalentInvitation/EApplication/ReferencesForm',
      '/TalentInvitation/EApplication/SupportingDocumentsForm',
      '/DataUpdate/EApplication',
      '/DataUpdate/EApplicationSubmitted',
      '/DataUpdate/EApplication/PersonalInfoForm',
      '/DataUpdate/EApplication/EducationForm',
      '/DataUpdate/EApplication/EmploymentHistoryForm',
      '/DataUpdate/EApplication/ReferencesForm',
      '/DataUpdate/EApplication/SupportingDocumentsForm',
      '/DataUpdate/EApplication/DependentForm',
      '/DataUpdate/EApplication/CertificateForm',
      '/DataUpdate/EApplication/HealthInfoForm',
      '/DataUpdate/EApplication/StatutoryInfoForm',
    ],
    strict: true,
    sensitive: true,
  })
  const [isDrawerReady, setIsDrawerReady] = useState(!!settings) // Always true if user is in settings page
  // Desktop Checking
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  // Drawer Toggler
  // const [openDrawer, setOpenDrawer] = useState(true)
  // const handleDrawerToggle = () => {
  //   setOpenDrawer(!openDrawer)
  // }
  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }
  const { globalState, dispatch } = useContext(AppContext) as any

  return (
    <div className={classes.root}>
      {!settings &&
        (isDesktop ? (
          <Header onDrawerOpen={handleDrawer} />
        ) : // <Header onDrawerOpen={handleDrawer} />
        null)}
      {!settings && (
        // !loading &&
        <Drawer
          loading={loading}
          isDrawerReady={isDrawerReady}
          setIsDrawerReady={setIsDrawerReady}
          onCloseDrawer={isDesktop ? !handleDrawer : handleDrawer}
          open={isDesktop ? globalState.drawerOpen : !globalState.drawerOpen}
          variant={isDesktop ? 'persistent' : 'temporary'}
          mobile={mobile}
        />
      )}

      {!loading && isDrawerReady ? (
        <main
          className={clsx(classes.mainContent, {
            [classes.contentShift]: globalState.drawerOpen,
          })}
        >
          {cloneElement(children, {})}
        </main>
      ) : (
        // Make sure drawer is always rendered before main content.
        // This is pages failed to retrieve loggedInEmployees/loggedInUser and causing no permission or blank page.
        <Skeleton variant="rect" width="100%" height="100vh" />
      )}
    </div>
  )
}
export default Layout
