import DateFnsUtils from '@date-io/date-fns'
import { useStatesListingQuery } from '@hr-root/hr-employee-react/src/generated/graphql'
import DefaultAvatar from '@ifca-root/react-component/src/assets/images/default-avatar.png'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Fab,
  FormHelperText,
  Grid,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Publish } from '@material-ui/icons'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import { lightenColor } from 'containers/helper/ColorConverter'
import dateFormat from 'dateformat'
import {
  DocumentType,
  Gender,
  Marital,
  Nationality,
  Race,
  Religion,
  useTalentUserDefineFieldsListingLazyQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation } from 'react-router'
import useInputStyles from '../../CustomInputStyles'

interface PersonalInfoForm {
  ProfileImage: string
  Name: string
  Email: string
  NickName: string
  NricNo: string
  PassportNo: string
  Gender: string
  MobileNo: string
  Dob: Date
  Dob2: Date
  Age: number
  Nationality: string
  Race: string
  Religion: string
  Marital: string
  NoOfChildren: number
  AvailabilityDate: Date
  ExpectedSalary: number
  CurrentLocation: string
  PreferredLocation: string
}

const useStyles = themeColor =>
  makeStyles({
    cardHeader: {
      '&.MuiCardHeader-root': {
        color: `${themeColor} !important`,
        backgroundColor: `${lightenColor(themeColor, 90)} !important`,
      },
    },
  })

export const PersonalInfoForm = (props: any) => {
  // Define section
  const history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const SubscriptionInfo = state?.SubscriptionInfo
  const TalentInfo = state?.TalentInfo
  const requiredField = 'This field is required'
  const PersonalInfo = state?.ListingData?.find(
    x => x?.section_name === 'Personal Info'
  )?.DataList
  const ContactID = PersonalInfo?.Contact?.ContactID || null
  const TalentPoolID = PersonalInfo?.Talent?.rec_talent_pool_id || null
  const JobPortal = state?.JobPortal
  const inputClasses = useInputStyles(JobPortal?.theme_color)()
  const classes = useStyles(JobPortal?.theme_color)()

  const Nationalities: any[] = Object.values(Nationality).map(i => {
    return i.replace(/_/g, ' ')
  })
  const Races: any[] = Object.values(Race).map(i => {
    return i.replace(/_/g, ' ')
  })
  const Religions: any[] = Object.values(Religion)
  const Genders: any[] = Object.values(Gender)
  const MaritalStatus: any[] = Object.values(Marital)

  const {
    handleSubmit,
    errors,
    setError,
    clearErrors,
    control,
    setValue,
    getValues,
    watch,
    formState,
    register,
    reset,
  } = useForm<PersonalInfoForm>({
    defaultValues: {
      ProfileImage: PersonalInfo?.Contact?.DocumentID || '',
      Name: PersonalInfo?.Contact?.FullName || TalentInfo?.name || '',
      Email: PersonalInfo?.Contact?.Email || TalentInfo?.email || '',
      NickName: PersonalInfo?.Contact?.NickName || '',
      NricNo: PersonalInfo?.Contact?.NRIC || '',
      PassportNo: PersonalInfo?.Contact?.PassportNo || '',
      MobileNo: PersonalInfo?.Contact?.MobileNo || '',
      Gender: PersonalInfo?.Contact?.Gender || '',
      Dob: PersonalInfo?.Contact?.BirthDate
        ? dateFormat(PersonalInfo?.Contact?.BirthDate, 'dd mmm yyyy')
        : '',
      Dob2: PersonalInfo?.Contact?.BirthDate || null,
      Age: PersonalInfo?.Contact?.Age || null,
      Nationality: PersonalInfo?.Contact?.Nationality || Nationality.Malaysian,
      Race: PersonalInfo?.Contact?.Race || '',
      Religion: PersonalInfo?.Contact?.Religion || '',
      Marital: PersonalInfo?.Contact?.Marital || '',
      NoOfChildren:
        PersonalInfo?.Talent?.no_of_children !== undefined
          ? PersonalInfo?.Talent?.no_of_children
          : null,
      AvailabilityDate: PersonalInfo?.Talent?.availability_date || null,
      ExpectedSalary:
        PersonalInfo?.Talent?.expected_salary !== undefined
          ? PersonalInfo?.Talent?.expected_salary
          : null,
      CurrentLocation: PersonalInfo?.Talent?.current_location || '',
      PreferredLocation: PersonalInfo?.Talent?.preferred_location || '',
    },
  })

  const { isDirty } = formState

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [imagePreview, setImagePreview] = useState(null)
  const [selectedFileName, setSelectedFileName] = useState('')
  const [selectedFile, setSelectedFile] = useState({
    files: [],
  })
  const [isDisable, setIsDisable] = useState(false)
  const [talentUserDefineField, setTalentUserDefineField] = useState(
    PersonalInfo?.Talent?.additional_user_field || []
  )

  // Query
  const {
    data: { StatesListing } = { StatesListing: [] },
    loading: StatesListingLoading,
  } = useStatesListingQuery({
    variables: {
      Country: 'Malaysia',
    },
    fetchPolicy: 'no-cache',
  })

  const [
    getTalentUserDefineFieldsListing,
    {
      data: { TalentUserDefineFieldsListing } = {
        TalentUserDefineFieldsListing: [],
      },
      loading: TalentUserDefineFieldsListingLoading,
    },
  ] = useTalentUserDefineFieldsListingLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      SubscriptionAccountID: SubscriptionInfo?.SubscriptionAccountID,
    },
  })

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false, null)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  useEffect(() => {
    if (type !== 'DataUpdate') {
      getTalentUserDefineFieldsListing({
        variables: {
          SubscriptionAccountID: SubscriptionInfo?.SubscriptionAccountID,
        },
      })
    }
  }, [type])

  useEffect(() => {
    if (PersonalInfo) {
      if (PersonalInfo?.Document?.Attachment) {
        setSelectedFile({
          files: [PersonalInfo?.Document?.Attachment],
        })

        let reader = new FileReader()

        reader.onloadend = () => {
          setImagePreview(reader.result)
        }
        reader.readAsDataURL(PersonalInfo?.Document?.Attachment)
      } else if (PersonalInfo?.Contact?.DocumentFile) {
        setImagePreview(PersonalInfo?.Contact?.DocumentFile)
      }
    }
  }, [PersonalInfo])

  useEffect(() => {
    if (
      watch('NricNo') === '' &&
      watch('Nationality') === Nationality.Malaysian
    ) {
      clearErrors('NricNo')
      setValue('Dob', '')
      setValue('Age', '-')
      setValue('PassportNo', '')
    }

    const NricElem = document.getElementById('NricNo')

    if (NricElem) {
      NricElem.onkeydown = (e: KeyboardEvent) => {
        const allowedKeys = [
          'Backspace',
          'Delete',
          'ArrowLeft',
          'ArrowRight',
          'Tab',
          'Control',
          'Meta',
          'Shift',
          'Alt',
          'Enter',
          'Escape',
        ]

        const isShortcut = e.ctrlKey || e.metaKey || e.altKey || e.shiftKey

        if (isShortcut || allowedKeys.includes(e.key) || /^\d$/.test(e.key)) {
          return // Allow the input
        }

        e.preventDefault()
      }

      NricElem.oninput = (e: Event) => {
        const target = e.target as HTMLInputElement
        const cursorPos = target.selectionStart ?? 0

        // Allow only numbers in the NRIC input
        let numericValue = ''
        for (const char of target.value) {
          if (!isNaN(Number(char))) {
            numericValue += char
          }
        }

        // Format the value with hyphens
        let formattedNric = numericValue
        if (numericValue.length > 6) {
          formattedNric = `${numericValue.slice(0, 6)}-${numericValue.slice(6)}`
        }
        if (numericValue.length > 8) {
          formattedNric = `${numericValue.slice(0, 6)}-${numericValue.slice(
            6,
            8
          )}-${numericValue.slice(8)}`
        }

        setValue('NricNo', formattedNric)

        // Calculate new cursor position
        let newCursorPos = cursorPos
        if (cursorPos > 6) newCursorPos += 1 // Account for the first hyphen
        if (cursorPos > 8) newCursorPos += 1 // Account for the second hyphen

        requestAnimationFrame(() => {
          target.setSelectionRange(newCursorPos, newCursorPos)
        })
      }
    }

    const nric = watch('NricNo')?.replace(/-/g, '')
    const nationality = watch('Nationality')

    if (nric?.length === 12 && nationality === Nationality.Malaysian) {
      const nricSplit = `${nric.slice(0, 6)}-${nric.slice(6, 8)}-${nric.slice(
        8
      )}`.split('-')

      if (
        nricSplit[0]?.length === 6 &&
        nricSplit[1]?.length === 2 &&
        nricSplit[2]?.length === 4
      ) {
        const year = nricSplit[0].substring(0, 2)
        const month = nricSplit[0].substring(2, 4)
        const day = nricSplit[0].substring(4)
        const currentYear = new Date()
          .getFullYear()
          .toString()
          .substring(2)
        const genderCode = Number(nricSplit[2].substring(3))

        // Determine gender
        setValue('Gender', genderCode % 2 === 0 ? 'Female' : 'Male')

        // Determine full year
        const fullYear = year > currentYear ? `19${year}` : `20${year}`

        // Validate month and day
        if (Number(month) >= 1 && Number(month) <= 12) {
          const date = new Date(`${fullYear}-${month}-${day}`)
          if (date.getDate() === Number(day) && !isNaN(date.getTime())) {
            // Check if date is valid
            setValue('Dob', dateFormat(date, 'dd mmm yyyy'))
            setValue('Age', calculateAge(date))
            clearErrors('NricNo')
            clearErrors('Gender')
          } else {
            setError('NricNo', {
              type: 'validate',
              message: 'Invalid NRIC No.',
            })
          }
        } else {
          setError('NricNo', {
            type: 'validate',
            message: 'Invalid NRIC No.',
          })
        }
      }
    } else if (nationality !== Nationality.Malaysian) {
      setValue('NricNo', '')
      if (watch('Dob2')) {
        setValue('Age', calculateAge(new Date(watch('Dob2'))))
      } else {
        setValue('Age', '-')
      }

      if (!watch('Gender')) {
        setValue('Gender', '')
      }
    }
  }, [watch('NricNo'), watch('Nationality'), watch('Dob2'), watch('Gender')])

  // Function
  const onSubmit = (data: PersonalInfoForm) => {
    setIsDisable(true)
    let documentInput = null

    let contactInput = {
      ...PersonalInfo?.Contact,
      ContactID,
      FullName: data?.Name,
      Email: data?.Email,
      NickName: data?.NickName,
      MobileNo: data?.MobileNo,
      NRIC: data?.NricNo,
      PassportNo: data?.PassportNo,
      BirthDate: data?.Dob || data?.Dob2,
      Nationality: Object.values(Nationality).find(
        i => i === data?.Nationality?.replace(/ /g, '_')
      ),
      Race: Object.values(Race).find(i => i === data?.Race?.replace(/ /g, '_')),
      Religion: Object.values(Religion).find(
        i => i === data?.Religion?.replace(/ /g, '_')
      ),
      Gender: Object.values(Gender).find(
        i => i === data?.Gender?.replace(/ /g, '_')
      ),
      Marital: Object.values(Marital).find(
        i => i === data?.Marital?.replace(/ /g, '_')
      ),
      Age: data?.Age,
    }

    let talentInput = {
      ...PersonalInfo?.Talent,
      rec_talent_pool_id: TalentPoolID,
      no_of_children: Number(data?.NoOfChildren),
      availability_date: data?.AvailabilityDate,
      expected_salary: Number(data?.ExpectedSalary),
      current_location: data?.CurrentLocation,
      preferred_location: data?.PreferredLocation,
      additional_user_field: talentUserDefineField,
    }

    if (selectedFile.files.length > 0) {
      documentInput = {
        DocumentType: DocumentType.Employee,
        Attachment: selectedFile.files[0] || null,
        Description: selectedFile.files[0] ? selectedFile.files[0].name : '',
        FileSize: selectedFile.files[0].size,
      }
    }

    if (contactInput && talentInput) {
      const updatedData = {
        Contact: contactInput,
        Talent: talentInput,
        ...(documentInput ? { Document: documentInput } : {}),
      }

      snackBar('Saved Successfully!', true, updatedData)
    }
  }

  const calculateAge = (date: Date) => {
    const ageDiffMs = Date.now() - date?.getTime()
    const ageDate = new Date(ageDiffMs)
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970)

    if (date) {
      if (date.getFullYear() === new Date().getFullYear()) {
        return 0
      } else {
        return calculatedAge
      }
    } else {
      return null
    }
  }

  const snackBar = (message: string, redirect: boolean, updatedData: any) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...state,
          ListingData: state?.ListingData?.map(x => {
            if (x?.section_name === 'Personal Info') {
              return {
                ...x,
                DataList: updatedData,
              }
            }

            return x
          }),
        })
      }
    }, 2000)
  }

  const onChangeFile = event => {
    const file = event.target.files[0]

    if (!file) return

    if (file?.name === PersonalInfo?.Contact?.Documents?.Description) {
      let nextState = { ...selectedFile }
      nextState.files.splice(0, nextState.files.length)
      nextState.files.push(file)
      setSelectedFileName(file?.name)
      setSelectedFile(nextState)
      let reader = new FileReader()

      reader.onloadend = () => {
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    } else {
      if (file && file.type.startsWith('image/')) {
        clearErrors('ProfileImage')
        let nextState = { ...selectedFile }
        nextState.files.splice(0, nextState.files.length)
        nextState.files.push(file)
        setSelectedFileName(file?.name)
        setSelectedFile(nextState)
        let reader = new FileReader()

        reader.onloadend = () => {
          const blob = new Blob([reader.result], { type: file.type })
          const url = URL.createObjectURL(blob)
          setImagePreview(url)
        }
        reader.readAsArrayBuffer(file)
      } else {
        setError('ProfileImage', {
          type: 'validate',
          message: 'Invalid file type!',
        })
        setSelectedFile({ files: [] })
        setSelectedFileName('')
        setImagePreview(null)

        return
      }
    }
  }

  return (
    <>
      <BasicHeader
        mainBtn="close"
        onClick={() =>
          isDirty
            ? setopenExitDialog(true)
            : history.push(`/${type}/EApplication`, {
                ...state,
              })
        }
        title="E-Application"
        primary={'Personal Info'}
        themeColor={JobPortal?.theme_color || '#FF9800'}
      />

      <ContentWrapper externalBasicHeader footer>
        <CardContents>
          {imagePreview ? (
            <img src={imagePreview} alt="" className="upload-user-avatar" />
          ) : (
            <img src={DefaultAvatar} alt="" className="upload-user-avatar" />
          )}

          <Fab
            color="primary"
            size="small"
            aria-label="add"
            className="float-img-upload"
            style={{ backgroundColor: JobPortal?.theme_color }}
          >
            <label htmlFor="icon-button-file"></label>
            <Publish />
          </Fab>

          <Controller
            name={'ProfileImage'}
            control={control}
            ref={register}
            render={({ name, onChange }) => (
              <>
                <input
                  type="file"
                  hidden
                  name={name}
                  id="icon-button-file"
                  onChange={e => {
                    const file = e.target.files[0]
                    if (file && file.type.startsWith('image/')) {
                      onChangeFile(e)
                      onChange(file)
                      clearErrors('ProfileImage')
                    } else {
                      setError('ProfileImage', {
                        type: 'validate',
                        message: 'Invalid file type!',
                      })
                      setSelectedFile({ files: [] })
                      setSelectedFileName('')
                      setImagePreview(null)
                    }
                  }}
                />
              </>
            )}
          />
          {errors?.ProfileImage && (
            <Grid item xs={12} className="form-content">
              <FormHelperText error style={{ textAlign: 'center' }}>
                {errors?.ProfileImage?.message}
              </FormHelperText>
            </Grid>
          )}

          <Grid item xs={12} className="form-content">
            <Controller
              name={'Name'}
              label="Name"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Name"
                  required
                  fullWidth
                  className={inputClasses.textField}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.Name?.message}
                  error={!!errors.Name}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'Email'}
              label="Email"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Email"
                  required
                  fullWidth
                  className={inputClasses.textField}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.Email?.message}
                  error={!!errors.Email}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'NickName'}
              label="Nickname"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Nickname"
                  fullWidth
                  className={inputClasses.textField}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'MobileNo'}
              label="Mobile No."
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Mobile No."
                  required
                  fullWidth
                  className={inputClasses.textField}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.MobileNo?.message}
                  error={!!errors.MobileNo}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
                pattern: {
                  value: /^[+-]?[0-9]*\.?[0-9]+$/,
                  message: 'Invalid Mobile No.',
                },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'NricNo'}
              label="NRIC No."
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="NRIC No."
                  id="NricNo"
                  className={`${inputClasses.textField} left`}
                  fullWidth
                  required={watch('Nationality') === 'Malaysian'}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.NricNo?.message}
                  error={!!errors.NricNo}
                  disabled={watch('Nationality') !== 'Malaysian'}
                />
              )}
              rules={{
                required: {
                  value: watch('Nationality') === 'Malaysian',
                  message: requiredField,
                },
                pattern: {
                  value: /^\d{6}-\d{2}-\d{4}$/i,
                  message: 'Invalid NRIC No.',
                },
              }}
            />

            <Controller
              name={'PassportNo'}
              label="Current Passport No."
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Current Passport No."
                  className={`${inputClasses.textField} right`}
                  fullWidth
                  required={watch('Nationality') !== 'Malaysian'}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.PassportNo?.message}
                  error={!!errors.PassportNo}
                  disabled={watch('Nationality') === 'Malaysian'}
                />
              )}
              rules={{
                required: {
                  value: watch('Nationality') !== 'Malaysian',
                  message: requiredField,
                },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            {watch('Nationality') === 'Malaysian' ? (
              <Controller
                name="Dob"
                label="Birth Date"
                required
                fullWidth
                control={control}
                render={({ onChange, value, name }) => (
                  <TextField
                    label="Birth Date"
                    value={value}
                    name={name}
                    className={`${inputClasses.textField} left`}
                    autoComplete="off"
                    // helperText={validateDate && 'NRIC is incorrect'}
                    // error={validateDate}
                  />
                )}
              />
            ) : (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  name="Dob2"
                  control={control}
                  render={({ onChange, value, name }) => (
                    <KeyboardDatePicker
                      required
                      helperText={errors.Dob2?.message}
                      error={!!errors.Dob2}
                      label="Birth Date"
                      format="dd MMM yyyy"
                      onChange={date => [onChange(date)]}
                      value={value}
                      className={`${inputClasses.textField} left`}
                      name={name}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      DialogProps={{
                        className: inputClasses.datePicker,
                      }}
                    />
                  )}
                  rules={{
                    required: {
                      value: watch('Nationality') !== 'Malaysian',
                      message: requiredField,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            )}
            <Controller
              name={'Age'}
              label="Age"
              control={control}
              render={({ name, value }) => (
                <TextField
                  name={name}
                  label="Age"
                  fullWidth
                  value={value ?? '-'}
                  disabled
                  className={`${inputClasses.textField} right`}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                    style: {
                      color: 'black',
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name="Nationality"
              label="Nationality"
              required
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Nationality"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`${inputClasses.textField} left`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.Nationality?.message}
                  error={!!errors.Nationality}
                >
                  {Nationalities.map((el, index) => (
                    <MenuItem key={index} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />

            <Controller
              name="Race"
              label="Race"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Race"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`${inputClasses.textField} right`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.Race?.message}
                  error={!!errors.Race}
                >
                  {Races.map((el, index) => (
                    <MenuItem key={index} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name="Religion"
              label="Religion"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Religion"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`${inputClasses.textField} left`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.Religion?.message}
                  error={!!errors.Religion}
                >
                  {Religions.map((el, index) => (
                    <MenuItem key={index} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />

            <Controller
              name="Gender"
              label="Gender"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Gender"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`${inputClasses.textField} right`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.Gender?.message}
                  error={!!errors.Gender}
                >
                  {Genders.map((el, index) => (
                    <MenuItem key={index} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name="Marital"
              label="Marital Status"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Marital Status"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`${inputClasses.textField} left`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.Marital?.message}
                  error={!!errors.Marital}
                >
                  {MaritalStatus.map((el, index) => (
                    <MenuItem key={index} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />

            <Controller
              name="NoOfChildren"
              label="No. of Children"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <NumberFormat
                  allowNegative={false}
                  customInput={TextField}
                  label={`No. of Children`}
                  name={name}
                  required
                  min={0}
                  autoComplete="off"
                  value={value}
                  className={`${inputClasses.textField} right`}
                  onValueChange={e => {
                    onChange(e.value)
                  }}
                  helperText={errors.NoOfChildren?.message}
                  error={!!errors.NoOfChildren}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
                validate: value => value >= 0 || 'Value cannot be less than 0',
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="AvailabilityDate"
                control={control}
                render={({ onChange, value, name }) => {
                  return (
                    <KeyboardDatePicker
                      required
                      helperText={errors.AvailabilityDate?.message}
                      error={!!errors.AvailabilityDate}
                      label="Availability Date"
                      format="dd MMM yyyy"
                      onChange={date => [onChange(date)]}
                      value={value}
                      className={`${inputClasses.datePicker} left`}
                      name={name}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      minDate={new Date()}
                      DialogProps={{
                        className: inputClasses.datePicker,
                      }}
                    />
                  )
                }}
                rules={{
                  required: { value: true, message: requiredField },
                }}
              />
            </MuiPickersUtilsProvider>

            <Controller
              name="ExpectedSalary"
              label="Expected Salary"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <NumberFormat
                  allowNegative={false}
                  customInput={TextField}
                  label={`Expected Salary`}
                  name={name}
                  required
                  autoComplete="off"
                  fixedDecimalScale
                  decimalScale={2}
                  value={value}
                  prefix="RM "
                  className={`${inputClasses.textField} right`}
                  onValueChange={e => {
                    onChange(e.value)
                  }}
                  helperText={errors.ExpectedSalary?.message}
                  error={!!errors.ExpectedSalary}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name="CurrentLocation"
              label="Current Location"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Current Location"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`${inputClasses.textField} left`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.CurrentLocation?.message}
                  error={!!errors.CurrentLocation}
                  disabled={StatesListingLoading}
                >
                  {StatesListing?.map((el, index) => (
                    <MenuItem key={index} value={el?.Description}>
                      {el?.Description}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />

            <Controller
              name="PreferredLocation"
              label="Preferred Location"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Preferred Location"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`${inputClasses.textField} right`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.PreferredLocation?.message}
                  error={!!errors.PreferredLocation}
                  disabled={StatesListingLoading}
                >
                  {StatesListing?.map((el, index) => (
                    <MenuItem key={index} value={el?.Description}>
                      {el?.Description}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>
        </CardContents>

        {TalentUserDefineFieldsListing &&
          TalentUserDefineFieldsListing?.length > 0 && (
            <CardContents
              section={{
                header: {
                  title: 'Others',
                  customClass: classes.cardHeader,
                },
              }}
            >
              {TalentUserDefineFieldsListing?.map((el, index) => (
                <Grid item xs={12} className="form-content">
                  <Controller
                    name={`UserDefineField-${index}`}
                    required
                    control={control}
                    defaultValue={
                      talentUserDefineField?.find(
                        x => x?.id === el?.rec_additional_user_field_id
                      )?.value || ''
                    }
                    render={({ onChange, value, name }) => (
                      <TextField
                        select={el?.field_type === 'dropDownValues'}
                        label={el?.field_label}
                        value={value}
                        name={name}
                        required={el?.is_required}
                        fullWidth
                        className={`${inputClasses.textField}`}
                        onChange={e => {
                          const newValue = e.target.value
                          onChange(newValue)

                          // Create a new array with the updated value or add a new entry if it doesn't exist
                          const updatedFields = talentUserDefineField.map(
                            field =>
                              field.id === el.rec_additional_user_field_id
                                ? { ...field, value: newValue }
                                : field
                          )

                          // If the field doesn't exist, add it
                          if (
                            !talentUserDefineField.some(
                              field =>
                                field.id === el.rec_additional_user_field_id
                            )
                          ) {
                            updatedFields.push({
                              id: el.rec_additional_user_field_id,
                              value: newValue,
                            })
                          }

                          setTalentUserDefineField(updatedFields)
                        }}
                        helperText={errors[`UserDefineField-${index}`]?.message}
                        error={!!errors[`UserDefineField-${index}`]}
                      >
                        {el?.field_type === 'dropDownValues' &&
                          el?.values?.map((x, i) => (
                            <MenuItem key={i} value={x?.Name}>
                              {x?.Name}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                    rules={{
                      required: {
                        value: el?.is_required,
                        message: requiredField,
                      },
                    }}
                  />
                </Grid>
              ))}
            </CardContents>
          )}
      </ContentWrapper>

      <Footer
        externalDisplay
        themeColor={JobPortal?.theme_color}
        options={[
          {
            onClick: () => {
              handleSubmit(onSubmit)()
            },
            name: 'Save',
            color: 'primary',
            disabled: isDisable,
          },
        ]}
      />

      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        headerBgColor={lightenColor(JobPortal?.theme_color, 90)}
        sections={{
          header: {
            children: (
              <ListItem
                className="remove-padding"
                style={{
                  backgroundColor: lightenColor(JobPortal?.theme_color, 90),
                }}
              >
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{
                          color: JobPortal?.theme_color || '#FF9800',
                        }}
                      >
                        Exit Confirmation
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <>
              <div>
                Are you sure you want to exit? Your changes will not be saved.
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                  style: {
                    backgroundColor: JobPortal?.theme_color,
                  },
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () =>
                    history.push(`/${type}/EApplication`, {
                      ...state,
                    }),
                  variant: 'contained',
                  color: 'primary',
                  style: {
                    backgroundColor: JobPortal?.theme_color,
                  },
                },
              },
            ],
          },
        }}
      />

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
