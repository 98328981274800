import React, { Suspense } from 'react'
import { Skeleton } from '@material-ui/lab'
interface SuspenseProps {
  children: any
}
const RouteSuspense = (props: SuspenseProps) => {
  const { children } = props

  return (
    <Suspense
      fallback={
        <Skeleton
          width="100%"
          height="100vh"
          animation="wave"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 0,
            transform: 'none',
          }}
        />
      }
    >
      {children}
    </Suspense>
  )
}

export default RouteSuspense
