import DateFnsUtils from '@date-io/date-fns'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  Grid,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import {
  PersonnelParameterType,
  Status,
  useRecruitmentParameterListingQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import NumberFormat from 'react-number-format'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import uuid from 'uuid'
import { lightenColor } from 'containers/helper/ColorConverter'
import useInputStyles from '../../CustomInputStyles'

interface EducationForm {
  StartDate: Date
  EndDate: Date
  EducationLevelID: string
  InstitutionName: string
  MajorID: string
  GradeID: string
  Cgpa: number
  OtherMajor: string
}

export const EducationForm = (props: any) => {
  // Define section
  let history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const SubscriptionInfo = state?.SubscriptionInfo
  const requiredField = 'This field is required'
  const mode = state?.mode
  const PersonnelEducationID = state?.EducationID
  const EducationInfo = state?.ListingData?.find(
    x => x?.section_name === 'Education'
  )?.DataList
  const CurrentInfo = EducationInfo?.find(
    x => x?.PersonnelEducationID === PersonnelEducationID
  )
  const JobPortal = state?.JobPortal
  const classes = useInputStyles(JobPortal?.theme_color)()

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
    formState,
    register,
    reset,
  } = useForm<EducationForm>({
    defaultValues: {
      StartDate: CurrentInfo?.StartDate || null,
      EndDate: CurrentInfo?.EndDate || null,
      EducationLevelID: CurrentInfo?.EducationLevelID || '',
      InstitutionName: CurrentInfo?.InstitutionName || '',
      MajorID: CurrentInfo?.MajorID || '',
      GradeID: CurrentInfo?.GradeID || '',
      Cgpa: CurrentInfo?.Cgpa !== undefined ? CurrentInfo?.Cgpa : null,
      OtherMajor: CurrentInfo?.OtherMajor || '',
    },
  })

  const { isDirty } = formState

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [isDisable, setIsDisable] = useState(false)

  // Query
  const {
    data: { RecruitmentParameterListing } = { RecruitmentParameterListing: [] },
    loading: RecruitmentParameterListingLoading,
  } = useRecruitmentParameterListingQuery({
    variables: {
      SubscriptionAccountID: SubscriptionInfo?.SubscriptionAccountID,
      type: [
        PersonnelParameterType.EducationLevel,
        PersonnelParameterType.Major,
        PersonnelParameterType.Grade,
      ],
    },
    fetchPolicy: 'no-cache',
  })

  const EducationList = RecruitmentParameterListing?.filter(
    el => el?.PersonnelParameterType === PersonnelParameterType.EducationLevel
  )

  const MajorList = RecruitmentParameterListing?.filter(
    el => el?.PersonnelParameterType === PersonnelParameterType.Major
  )

  const GradeList = RecruitmentParameterListing?.filter(
    el => el?.PersonnelParameterType === PersonnelParameterType.Grade
  )

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  // Function
  const onSubmit = (data: EducationForm, addNew: boolean) => {
    setIsDisable(true)
    let input = {
      PersonnelEducationID: CurrentInfo?.PersonnelEducationID || uuid(),
      StartDate: data?.StartDate,
      EndDate: data?.EndDate,
      EducationLevelID: data?.EducationLevelID,
      EducationLevel: EducationList?.find(
        x => x?.PersonnelParameterID === data?.EducationLevelID
      )?.Name,
      InstitutionName: data?.InstitutionName,
      MajorID: data?.MajorID,
      Description: MajorList?.find(
        x => x?.PersonnelParameterID === data?.MajorID
      )?.Name,
      GradeID: data?.GradeID || null,
      Grade: GradeList?.find(x => x?.PersonnelParameterID === data?.GradeID)
        ?.Name,
      CGPA: Number(data?.Cgpa) || null,
      major_detail:
        MajorList?.find(x => x?.PersonnelParameterID === data?.MajorID)
          ?.Name === 'Others (Please Specify)'
          ? data?.OtherMajor
          : null,
      Status: Status.Active,
    }

    if (mode === 'New') {
      EducationInfo?.push(input)
    } else if (mode === 'Edit') {
      const indexToEdit = EducationInfo?.findIndex(
        x => x?.PersonnelEducationID === PersonnelEducationID
      )

      if (indexToEdit !== -1) {
        EducationInfo[indexToEdit] = input
      }
    }

    EducationInfo?.sort(
      (a, b) => new Date(b.EndDate).getTime() - new Date(a.EndDate).getTime()
    )

    if (addNew) {
      state?.ListingData?.map(x => {
        if (x?.section_name === 'Education') {
          return {
            ...x,
            DataList: EducationInfo,
          }
        }

        return x
      })

      setIsDisable(false)
      reset()
      snackBar('Saved Successfully!', false)
    } else {
      snackBar('Saved Successfully!', true)
    }
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...state,
        })
      }
    }, 2000)
  }

  return (
    <>
      <BasicHeader
        mainBtn="close"
        onClick={() =>
          isDirty
            ? setopenExitDialog(true)
            : history.push(`/${type}/EApplication`, {
                ...state,
              })
        }
        title="E-Application"
        primary={'Education'}
        themeColor={JobPortal?.theme_color || '#FF9800'}
      />

      <ContentWrapper externalBasicHeader footer>
        <CardContents>
          <Grid item xs={12} className="form-content">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="StartDate"
                control={control}
                render={({ onChange, value, name }) => (
                  <KeyboardDatePicker
                    required
                    helperText={errors.StartDate?.message}
                    error={!!errors.StartDate}
                    label="Start Date"
                    format="dd MMM yyyy"
                    onChange={date => [onChange(date)]}
                    value={value}
                    className={`${classes.datePicker} left`}
                    name={name}
                    autoComplete="off"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    DialogProps={{
                      className: classes.datePicker,
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: requiredField },
                }}
              />
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="EndDate"
                control={control}
                render={({ onChange, value, name }) => (
                  <KeyboardDatePicker
                    required
                    helperText={
                      errors.EndDate?.message ||
                      (value &&
                        new Date(value) < new Date(watch('StartDate')) &&
                        'End Date cannot be less than Start Date')
                    }
                    error={
                      !!errors.EndDate ||
                      (value && new Date(value) < new Date(watch('StartDate')))
                    }
                    label="End Date"
                    format="dd MMM yyyy"
                    onChange={date => [onChange(date)]}
                    value={value}
                    className={`${classes.datePicker} right`}
                    name={name}
                    autoComplete="off"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    minDate={watch('StartDate')}
                    DialogProps={{
                      className: classes.datePicker,
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: requiredField },
                  validate: value =>
                    new Date(value) > new Date(watch('StartDate')) ||
                    'End Date cannot be less than Start Date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name="EducationLevelID"
              label="Education Level"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Education Level"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`${classes.textField}`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.EducationLevelID?.message}
                  error={!!errors.EducationLevelID}
                  disabled={RecruitmentParameterListingLoading}
                >
                  {EducationList.map((el, index) => (
                    <MenuItem key={index} value={el?.PersonnelParameterID}>
                      {el?.Name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name={'InstitutionName'}
              label="Institution Name"
              control={control}
              ref={register}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  label="Institution Name"
                  required
                  fullWidth
                  className={`${classes.textField}`}
                  autoComplete="off"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  helperText={errors.InstitutionName?.message}
                  error={!!errors.InstitutionName}
                />
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          <Grid item xs={12} className="form-content">
            <Controller
              name="MajorID"
              label="Major"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Major"
                  value={value}
                  name={name}
                  required
                  fullWidth
                  className={`${classes.textField}`}
                  onChange={e => onChange(e.target.value)}
                  helperText={errors.MajorID?.message}
                  error={!!errors.MajorID}
                  disabled={RecruitmentParameterListingLoading}
                >
                  {MajorList.map((el, index) => (
                    <MenuItem key={index} value={el?.PersonnelParameterID}>
                      {el?.Name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: requiredField },
              }}
            />
          </Grid>

          {MajorList?.find(x => x?.PersonnelParameterID === watch('MajorID'))
            ?.Name === 'Others (Please Specify)' && (
            <Grid item xs={12} className="form-content">
              <Controller
                name={'OtherMajor'}
                label="Others (Please Specify)"
                control={control}
                ref={register}
                render={({ onChange, value, name }) => (
                  <TextField
                    name={name}
                    label="Others (Please Specify)"
                    required
                    fullWidth
                    className={`${classes.textField}`}
                    autoComplete="off"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    helperText={errors.OtherMajor?.message}
                    error={!!errors.OtherMajor}
                  />
                )}
                rules={{
                  required: {
                    value: watch('MajorID') === 'Others (Please Specify)',
                    message: requiredField,
                  },
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} className="form-content">
            <Controller
              name="GradeID"
              label="Grade"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <TextField
                  select
                  label="Grade"
                  value={value}
                  name={name}
                  fullWidth
                  className={`${classes.textField} left`}
                  onChange={e => onChange(e.target.value)}
                  disabled={RecruitmentParameterListingLoading}
                >
                  {GradeList.map((el, index) => (
                    <MenuItem key={index} value={el?.PersonnelParameterID}>
                      {el?.Name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name="Cgpa"
              label="CGPA"
              required
              control={control}
              render={({ onChange, value, name }) => (
                <NumberFormat
                  allowNegative={false}
                  customInput={TextField}
                  label={`CGPA`}
                  name={name}
                  value={value}
                  fixedDecimalScale
                  decimalScale={2}
                  autoComplete="off"
                  className={`${classes.textField} right`}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                />
              )}
            />
          </Grid>
        </CardContents>
      </ContentWrapper>

      <Footer
        externalDisplay
        themeColor={JobPortal?.theme_color}
        options={
          mode === 'Edit'
            ? [
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, false))()
                  },
                  name: 'Save',
                  color: 'primary',
                  disabled: isDisable,
                },
              ]
            : [
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, true))()
                  },
                  name: 'Save & New',
                  color: 'primary',
                  disabled: isDisable,
                },
                {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data, false))()
                  },
                  name: 'Save & Exit',
                  color: 'primary',
                  disabled: isDisable,
                },
              ]
        }
      />

      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        headerBgColor={lightenColor(JobPortal?.theme_color, 90)}
        sections={{
          header: {
            children: (
              <ListItem
                className="remove-padding"
                style={{
                  backgroundColor: lightenColor(JobPortal?.theme_color, 90),
                }}
              >
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{
                          color: JobPortal?.theme_color || '#FF9800',
                        }}
                      >
                        Exit Confirmation
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <>
              <div>
                Are you sure you want to exit? Your changes will not be saved.
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                  style: {
                    backgroundColor: JobPortal?.theme_color,
                  },
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () =>
                    history.push(`/${type}/EApplication`, {
                      ...state,
                    }),
                  variant: 'contained',
                  color: 'primary',
                  style: {
                    backgroundColor: JobPortal?.theme_color,
                  },
                },
              },
            ],
          },
        }}
      />

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
