import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { FullHeader } from 'components/ExternalComponent/FullHeader'
import { dateConvert } from 'containers/helper/formatDate'
import {
  useJobPortalInfoQuery,
  useJobPostingListByJobPortalCountLazyQuery,
  useJobPostingListByJobPortalLazyQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useLocation } from 'react-router'

export const AvailableVacancyListing = (props: any) => {
  // Define
  const history = useHistory()
  const { state }: any = useLocation()
  const portalToken =
    window.location.pathname.split('/')?.pop() !== 'AvailableVacancyListing'
      ? window.location.pathname.split('/')?.pop()
      : state?.portalToken
  // ||
  //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTdWJzY3JpcHRpb25BY2NvdW50SUQiOiJjYWY3MDRjYS1mMWIyLTExZWItYjIwYy0yMzU1MDI1YzM2MWMiLCJ1c2VySUQiOiJmNzdiNTEwYS0zOWNmLTExZWYtODk2MS0xN2ViN2M2MTZmYWIiLCJpYXQiOjE3MjIwNDI2MDJ9.yCqEwQs-zNi_fCag1fNdBthj9k74RdemhCfP7mnaECI'
  const PAGINATION_LIMIT = 20

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [JobPortal, setJobPortal] = useState(null)
  const [JobPostingList, setJobPostingList] = useState([])

  // Query
  const {
    data: JobPortalInfo = { JobPortalInfo: null },
    loading: JobPortalInfoLoading,
  } = useJobPortalInfoQuery({
    fetchPolicy: 'no-cache',
    variables: {
      PortalToken: portalToken,
    },
    onCompleted: data => {
      if (data?.JobPortalInfo) {
        setJobPortal(data?.JobPortalInfo)
      }
    },
  })

  const [
    getJobPostingListByJobPortal,
    {
      loading: JobPostingListByJobPortalLoading,
      fetchMore: JobPostingListByJobPortalFetchMore,
    },
  ] = useJobPostingListByJobPortalLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      PortalToken: portalToken,
    },
    onCompleted: data => {
      if (data?.JobPostingListByJobPortal) {
        setJobPostingList(data?.JobPostingListByJobPortal)
      }
    },
  })

  const [
    getJobPostingListByJobPortalCount,
    {
      data: { JobPostingListByJobPortalCount } = {
        JobPostingListByJobPortalCount: null,
      },
      loading: JobPostingListByJobPortalCountLoading,
    },
  ] = useJobPostingListByJobPortalCountLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      PortalToken: portalToken,
    },
  })

  // useEffect
  useEffect(() => {
    if (!portalToken) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    } else {
      getJobPostingListByJobPortal({
        variables: {
          PortalToken: portalToken,
          offset: 0,
          limit: PAGINATION_LIMIT,
        },
      })

      getJobPostingListByJobPortalCount({
        variables: {
          PortalToken: portalToken,
        },
      })
    }
  }, [portalToken])

  // Function
  const fetchMoreList = () => {
    if (JobPostingListByJobPortalCount > 20) {
      JobPostingListByJobPortalFetchMore({
        variables: {
          PortalToken: portalToken,
          offset: JobPostingList?.length,
          limit: PAGINATION_LIMIT,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          const items: any[] = fetchMoreResult?.JobPostingListByJobPortal

          if (items?.length < 1) {
            return prev
          }

          setJobPostingList(prev => [...prev, ...items])
          return Object.assign({}, prev, {
            JobPostingListByJobPortal: [
              ...prev?.JobPostingListByJobPortal,
              ...items,
            ],
          })
        },
      })
    }
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.goBack()
      }
    }, 2000)
  }

  return (
    <>
      {JobPortalInfoLoading ? (
        <Skeleton
          variant="rect"
          width="100%"
          height={76}
          style={{
            position: 'fixed',
            width: '100%',
            zIndex: 1000,
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      ) : (
        <FullHeader
          themeColor={JobPortal?.theme_color}
          desktopBanner={JobPortal?.desktop_banner}
          mobileBanner={JobPortal?.mobile_banner}
        />
      )}

      <CardContents style={{ margin: '16px 4px 12px' }}>
        {JobPortalInfoLoading ? (
          <Skeleton
            variant="rect"
            width="100%"
            height={100}
            style={{
              borderRadius: 5,
            }}
          />
        ) : (
          <div
            className="quill-content"
            style={{
              fontSize: 12,
            }}
            dangerouslySetInnerHTML={{
              __html: JobPortal?.description,
            }}
          />
        )}
      </CardContents>

      <CardContents
        style={{ marginLeft: '4px', marginRight: '4px' }}
        contentStyle={{ paddingBottom: '4px', paddingTop: '4px' }}
      >
        <div
          className="content-wrap full"
          style={{ textAlign: 'center', marginBottom: 0 }}
        >
          <span
            className="xsTitle"
            style={{ color: JobPortal?.theme_color || 'black' }}
          >
            Available Vacancies
          </span>
        </div>
      </CardContents>

      <List
        className="core-list"
        style={{ marginLeft: '4px', marginRight: '4px' }}
      >
        {JobPostingListByJobPortalLoading ? (
          Array.from({ length: 10 }, (_, index) => (
            <Skeleton
              key={`skeleton-${index}`}
              animation="wave"
              variant="rect"
              width="100%"
              height={50}
              style={{
                marginBottom: 5,
                borderRadius: 5,
              }}
            />
          ))
        ) : JobPostingList?.length === 0 ? (
          <EmptyList title="No Record Found" />
        ) : (
          <InfiniteScroll
            next={fetchMoreList}
            hasMore={JobPostingListByJobPortalCount > JobPostingList?.length}
            loader={<LinearProgress color="primary" />}
            dataLength={JobPostingList?.length}
          >
            {JobPostingList?.map((el, index) => (
              <ListItem
                key={index}
                onClick={() =>
                  history.push(`/JobPortal/AvailableVacancyDetail`, {
                    // parse job posting id
                    portalToken,
                    JobPostingID: el?.rec_job_posting_id,
                  })
                }
                button
                dense
              >
                <ListItemText
                  primary={
                    <>
                      <div className="desc flex-space">
                        <span
                          className="xsTitle"
                          style={{ color: JobPortal?.theme_color }}
                        >
                          {el?.job_position}
                        </span>
                      </div>

                      <div className="desc">
                        <span className="xsDesc">
                          Closing Date : {dateConvert(el?.job_closing_date)}
                        </span>
                      </div>
                    </>
                  }
                  secondary={
                    <div className="desc flex-space">
                      <span className="xsDesc">{el?.location}</span>
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    tabIndex={-1}
                    onClick={() =>
                      history.push(`/JobPortal/AvailableVacancyDetail`, {
                        // parse job posting id
                        portalToken,
                        JobPostingID: el?.rec_job_posting_id,
                      })
                    }
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </InfiniteScroll>
        )}
      </List>

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
